import { PortalMergeWelcomeScreenDto } from 'types/dtos'
import { PortalMergeBannerModel } from 'types/models'

export const transformPortalMergeWelcomeScreen = ({
  image_url,
  heading,
  text,
  text_link_title,
  text_link_url,
  main_cta_title,
  main_cta_url,
  secondary_web_cta_title,
}: PortalMergeWelcomeScreenDto): PortalMergeBannerModel => ({
  animationUrl: undefined,
  imageUrl: image_url,
  heading,
  text,
  textLinkTitle: text_link_title,
  textLinkUrl: text_link_url,
  primaryActionTitle: main_cta_title,
  primaryActionUrl: main_cta_url,
  closeActionTitle: secondary_web_cta_title,
})
