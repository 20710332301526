'use client'

import Script from 'next/script'

import useAsset from 'hooks/useAsset'

import { ConsentGroup } from 'constants/consent'
import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

const SCRIPT_URLS = {
  mymedia: '//vinted-1.leadsmonitor.io/track.js',
}

// TODO: check if this is okay when routing from next as it might not retrigger
const BottomScripts = () => {
  const mymediaTrackingPixel = useFeatureSwitch('mymedia_tracking_pixel')
  const isFullstoryEnabled = useFeatureSwitch('web_fullstory_integration')
  const hasConsentedTargeting = useIsConsentGroupEnabled(ConsentGroup.Targeting)
  const asset = useAsset()

  const renderMymedia = () => {
    if (!hasConsentedTargeting) return null
    if (!mymediaTrackingPixel) return null

    return (
      <>
        <div id="_lm_pix" className="is-visually-hidden u-sticky-bottom" />
        <Script
          id="mymedia-script"
          data-testid="mymedia-script"
          src={SCRIPT_URLS.mymedia}
          strategy="afterInteractive"
        />
      </>
    )
  }

  const renderFullstory = () => {
    if (!isFullstoryEnabled) return null

    return <Script data-testid="fullstory-script" src={asset('fullstory.js')} />
  }

  return (
    <>
      {renderMymedia()}
      {renderFullstory()}
    </>
  )
}

export default BottomScripts
