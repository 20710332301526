import Relay from './event-tracker/relay'
import Store from './event-tracker/store'
import EventTracker from './event-tracker/event-tracker'

const EVENT_TRACKER_STORE_NAMESPACE = 'VintedEvents'
const EVENT_TRACKER_RELAY_URL = '/relay/events'

const store = new Store(EVENT_TRACKER_STORE_NAMESPACE)
const relay = new Relay(EVENT_TRACKER_RELAY_URL)
const tracker = new EventTracker({ store, relay })

export { store, relay, tracker }
