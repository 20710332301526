export enum ItemAlertStatus {
  MissingSubcategory = 'missing_subcategory',
  ChangeDescription = 'change_description',
  Black = 'black',
  DarkGray = 'dark_gray',
  PackageSize = 'package_size',
  ReplicaProof = 'replica_proof',
  UnderReview = 'under_review',
  EligibleForAuthenticity = 'eligible_for_authenticity',
  VerifiedOnlineAuthenticity = 'verified_online_authenticity',
  DelayedPublication = 'delayed_publication',
}

export enum ItemClosingAction {
  Sold = 'sold',
}

export enum ItemDisposalConditions {
  None = 0,
  ForSell = 4,
}

export enum ItemPageRedirectReason {
  IdSlugNotString = 'item_id_slug_not_string',
  IdDoesNotExist = 'item_id_does_not_exist',
  ItemDoesNotExist = 'item_does_not_exist',
  IdSlugNotMatching = 'item_id_slug_not_matching_query',
  BannerOwner = 'banned_owner',
  DraftItem = 'draft_item',
  PermittedTransaction = 'permitted_transaction',
  CannotViewProfile = 'cannot_view_profile',
  IdDoesNotMeetRegex = 'id_does_not_meet_regex',
}
