import { useContext } from 'react'

import { EnvsContext } from '../../contexts/EnvsProvider'
import { UniversalEnvs } from '../../../next/libs/server-utils/environment'

const useEnvs = (env: keyof UniversalEnvs) => {
  const envs = useContext(EnvsContext)

  if (!envs) throw new Error('useEnvs must be used within an EnvsProvider')

  return envs[env]
}

export default useEnvs
