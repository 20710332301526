import { DigitalLabelTypeDto } from 'types/dtos'
import { DigitalLabelTypeModel } from 'types/models'

export const transformDigitalLabelType = ({
  icon_url,
  subtitle,
  title,
  type,
}: DigitalLabelTypeDto): DigitalLabelTypeModel => ({
  iconUrl: icon_url,
  subtitle,
  title,
  type,
})

export const transformDigitalLabelTypes = (
  data: Array<DigitalLabelTypeDto>,
): Array<DigitalLabelTypeModel> => data.map(transformDigitalLabelType)
