import { BannersDto } from 'types/dtos'
import { BannersModel } from 'types/models'

import { transformFeedPersonalization } from './feed-personalization-banner'
import { transformListerActivationBanners } from './lister-activation-banner'
import { transformNps } from './nps-banner'
import {
  transformMultiVariantsOnboardingModal,
  transformOnboardingModal,
} from './onboarding-banner'
import { transformTermsAndConditions } from './terms-and-conditions-banner'
import { transformIvsGuidelineBanner } from './ivs-guideline-banner'
import { transformPortalMergeDraftItemsReminder } from './portal-merge-draft-items-reminder-banner'
import { transformPromotionalListingBannerInCatalogFeed } from './promotional-listing-banner-in-catalog-feed-banner'
import { transformTaxpayerBanner } from './taxpayer-banner'
import { transformBusinessAccountBanner } from './business-account-banner'
import { transformEprBanner } from './epr-banner'
import { transformPortalMergeAnnouncement } from './portal-merge-announcement-banner'
import { transformCatalogRulesBanner } from './catalog-rules-banner'
import { transformPortalMergeWelcomeScreen } from './portal-merge-welcome-screen-banner'

export const transformBanners = ({
  feed_personalization_banner,
  nps,
  email_confirmation,
  terms_and_conditions,
  portal_merge_source_announcement,
  portal_merge_welcome_screen,
  portal_merge_draft_items_reminder,
  lister_activation,
  onboarding_modal,
  multi_variants_onboarding_modal,
  business_account,
  business_account_epr,
  catalog_rules,
  taxpayer_banner,
  promotional_listing_banner_in_catalog_feed,
  ivs_guideline_banner,
}: BannersDto): BannersModel => ({
  feedPersonalizationBanner:
    feed_personalization_banner && transformFeedPersonalization(feed_personalization_banner),
  nps: nps && transformNps(nps),
  emailConfirmation: email_confirmation,
  termsAndConditions: terms_and_conditions && transformTermsAndConditions(terms_and_conditions),
  portalMergeSourceAnnouncement:
    portal_merge_source_announcement &&
    transformPortalMergeAnnouncement(portal_merge_source_announcement),
  portalMergeWelcomeScreen:
    portal_merge_welcome_screen && transformPortalMergeWelcomeScreen(portal_merge_welcome_screen),
  portalMergeDraftItemsReminder:
    portal_merge_draft_items_reminder &&
    transformPortalMergeDraftItemsReminder(portal_merge_draft_items_reminder),
  listerActivation: transformListerActivationBanners(lister_activation),
  onboardingModal: onboarding_modal && transformOnboardingModal(onboarding_modal),
  multiVariantsOnboardingModal:
    multi_variants_onboarding_modal &&
    transformMultiVariantsOnboardingModal(multi_variants_onboarding_modal),
  businessAccount: business_account && transformBusinessAccountBanner(business_account),
  businessAccountEpr: business_account_epr && transformEprBanner(business_account_epr),
  catalogRules: catalog_rules && transformCatalogRulesBanner(catalog_rules),
  taxpayerBanner: taxpayer_banner && transformTaxpayerBanner(taxpayer_banner),
  promotionalListingBannerInCatalogFeed:
    promotional_listing_banner_in_catalog_feed &&
    transformPromotionalListingBannerInCatalogFeed(promotional_listing_banner_in_catalog_feed),
  ivsGuidelineBanner: ivs_guideline_banner && transformIvsGuidelineBanner(ivs_guideline_banner),
})
