'use client'

import { Image } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'

const ErrorHeader = () => {
  const asset = useAsset('/assets/web-logo/default')

  return (
    <div className="l-header">
      <header className="l-header__main">
        <div className="container u-flexbox u-align-items-center u-fill-height">
          <div className="u-flexbox u-align-items-center u-fill-width">
            <div className="l-header__logo">
              <a className="u-block" href="/">
                <div className="l-header__logo-container">
                  <Image src={asset('logo.svg')} alt="Vinted Home" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default ErrorHeader
