import { useEffect } from 'react'

import useTracking from 'hooks/useTracking'
import { viewScreenEvent } from 'libs/common/event-tracker/events'
import useSession from 'hooks/useSession'

type Props = {
  children: JSX.Element
}

const TrackScreen = ({ children }: Props) => {
  const { track } = useTracking()
  const { screen } = useSession()

  useEffect(() => {
    track(viewScreenEvent({ screen }))
  }, [screen, track])

  return children
}

export default TrackScreen
