import { withServerSideProxy } from './proxy'

const localStorageHelpers = {
  getLocalStorageItem: (key: string) => localStorage.getItem(key),
  removeLocalStorageItem: (key: string) => localStorage.removeItem(key),
  setLocalStorageItem: (key: string, value: string) => localStorage.setItem(key, value),
}

const serverSafeLocalStorageHelpers = withServerSideProxy(localStorageHelpers, {
  getLocalStorageItem: () => null,
})

export const { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } =
  serverSafeLocalStorageHelpers
