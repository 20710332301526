export const EMPTY_USER_IMAGE_NAME = 'user-empty-state.svg'

export enum ImageOrientation {
  Degree0 = 0,
  Degree90 = 1,
  Degree180 = 2,
  Degree270 = 3,
}

// Based on UserPhoto::PHOTO_SIZES
export enum UserThumbnailSize {
  Small = 'thumb20',
  Medium = 'thumb30',
  Default = 'thumb50',
  Large = 'thumb70',
  XLarge = 'thumb90',
  X2Large = 'thumb100',
  X3Large = 'thumb140',
  X4Large = 'thumb150',
  X5Large = 'thumb175',
  X6Large = 'thumb310',
}

// Based on ItemPhoto::PHOTO_SIZES
export enum ItemThumbnailSize {
  Small = 'thumb70x100',
  Medium = 'thumb150x210',
  Large = 'thumb310x430',
  XLarge = 'thumb364x428',
  X2Large = 'thumb624x428',
}

export const USER_THUMB_SIZE = UserThumbnailSize.Default
