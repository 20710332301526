import { ShipmentDto } from 'types/dtos/shipment'
import { ShipmentModel } from 'types/models/shipment'

import { transformAddress } from './address'
import { transformCarrierDto } from './carrier'
import { transformPackageTypeDto } from './package-type'
import { transformShippingPointDto } from './shipping-point'
import { transformDigitalLabelTypes } from './digital-label-types'

export const transformShipmentDto = ({
  id,
  price,
  status,
  carrier,
  currency,
  label_url,
  rate_uuid,
  to_address,
  from_address,
  package_type,
  pickup_point,
  tracking_code,
  digital_label_types,
  label_type,
}: ShipmentDto): ShipmentModel => ({
  id,
  price,
  status,
  currency,
  rateUuid: rate_uuid,
  labelUrl: label_url,
  trackingCode: tracking_code,
  carrier: transformCarrierDto(carrier),
  packageType: transformPackageTypeDto(package_type),
  toAddress: to_address && transformAddress(to_address),
  fromAddress: from_address && transformAddress(from_address),
  pickupPoint: pickup_point && transformShippingPointDto(pickup_point),
  digitalLabelTypes: digital_label_types && transformDigitalLabelTypes(digital_label_types),
  shipmentLabelType: label_type && label_type,
})
