import { createSelector } from 'reselect'
import { size } from 'lodash'

import { UiState } from 'constants/ui'
import { PickupModalElement, DeliveryType } from 'constants/checkout'

import { AllState, CheckoutState } from '../types'
import { stateName } from '../constants'

const checkoutState = (state: AllState): CheckoutState => state[stateName]
const localState = (state: AllState) => checkoutState(state).shipping

const getCheckoutState = createSelector(checkoutState, ({ checkout }) => checkout)

const getCheckoutUiState = createSelector(getCheckoutState, ({ ui }) => ui)
const getCheckoutData = createSelector(getCheckoutState, ({ checkoutData }) => checkoutData)

export const getCheckoutShippingService = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.services.shipping,
)

export const getDeliveryTypes = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.services.shipping?.deliveryTypes || null,
)

export const getSelectedIntegratedShippingOption = createSelector(
  getCheckoutShippingService,
  checkoutShippingService => {
    switch (checkoutShippingService?.selectedDeliveryType) {
      case DeliveryType.Home: {
        const selectedPackageTypeId =
          checkoutShippingService?.deliveryTypes?.home?.selectedPackageTypeId

        return checkoutShippingService?.deliveryTypes?.home?.shippingOptions?.find(
          shippingOption => shippingOption.packageTypeId.toString() === selectedPackageTypeId,
        )
      }
      case DeliveryType.PickupPoint:
        return checkoutShippingService?.deliveryTypes?.pickup?.shippingOption
      default:
        return undefined
    }
  },
)

export const getCountryBounds = createSelector(localState, ({ countryBounds }) => countryBounds)

export const getNearbyPoints = createSelector(localState, ({ nearbyPoints }) => nearbyPoints)

export const getUiState = createSelector(localState, ({ ui }) => ui)

export const getPickupModalUiState = createSelector(getUiState, ({ pickupModal }) => pickupModal)

export const getPickupModalUiStateByElement = (state: AllState, element: PickupModalElement) =>
  localState(state).ui.pickupModal[element]

export const getIsPickupModalElementLoaded = (state: AllState, element: PickupModalElement) =>
  [UiState.Success, UiState.Failure].includes(localState(state).ui.pickupModal[element]!)

export const getPickupPoint = createSelector(
  getDeliveryTypes,
  deliveryTypes => deliveryTypes?.pickup?.shippingPoint || null,
)

export const getIsShipmentActionable = createSelector(
  getDeliveryTypes,
  deliveryTypes => !deliveryTypes?.custom && !deliveryTypes?.meetup,
)

export const getSelectedDeliveryType = createSelector(
  getCheckoutShippingService,
  checkoutShippingService => checkoutShippingService?.selectedDeliveryType || null,
)

export const getDeliveryPickupPointDetails = createSelector(getDeliveryTypes, deliveryTypes => ({
  title: deliveryTypes?.pickup?.shippingOption?.title,
  carrierCode: deliveryTypes?.pickup?.shippingOption?.carrierCode,
}))

export const getBuyerPhoneRequirement = createSelector(
  getCheckoutShippingService,
  shippingService => shippingService?.buyerPhoneRequirement,
)

export const getSuggestedShippingPointCode = createSelector(
  localState,
  ({ suggestedShippingPointCode }) => suggestedShippingPointCode,
)

export const getIsIntergratedShipping = createSelector(
  getDeliveryTypes,
  deliveryTypes => !!deliveryTypes?.home || !!deliveryTypes?.pickup,
)

export const getSelectedShippingPoint = createSelector(
  localState,
  ({ selectedShippingPoint }) => selectedShippingPoint,
)

export const getPickupPointsUiState = createSelector(getUiState, ({ pickupPoints }) => pickupPoints)

export const getShippingOptions = createSelector(
  localState,
  ({ shippingOptions }) => shippingOptions,
)

export const getDeliveryTypesUiState = createSelector(
  getCheckoutUiState,
  ({ checkoutData }) => checkoutData,
)

export const getDeliveryTypesSize = createSelector(getDeliveryTypes, deliveryTypes =>
  size(deliveryTypes),
)

export const getRateUuid = createSelector(
  getSelectedIntegratedShippingOption,
  selectedIntegratedShippingOption => selectedIntegratedShippingOption?.rateUuid,
)

export const getVerificationServiceType = createSelector(
  getSelectedIntegratedShippingOption,
  selectedIntegratedShippingOption => selectedIntegratedShippingOption?.verificationServiceType,
)
