import { ErrorInfo } from 'react'

import { Spacer, Text } from '@vinted/web-ui'

type Props = {
  error: Error | null
  errorInfo: ErrorInfo | null
}

const StackTrace = ({ error, errorInfo }: Props) => (
  <>
    <Text as="h3" type={Text.Type.Subtitle} theme="inherit" width={Text.Width.Parent}>
      {error?.toString()}
    </Text>
    <Spacer />
    <details>
      <summary>Component Stack</summary>
      <Text as="h4" type={Text.Type.Caption} theme="inherit">
        {errorInfo?.componentStack}
      </Text>
    </details>
  </>
)

export default StackTrace
