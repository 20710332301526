import { snakeCase as lodashSnakeCase, camelCase as lodashCamelCase } from 'lodash'

const truncateString = (text: string, length: number) =>
  text.length > length ? `${text.substring(0, length)}...` : text

const truncateFilename = (filename: string, length: number) => {
  const match = filename.match(/(?<name>^.*)\.(?<extension>.*)/)

  if (!match?.groups) return truncateString(filename, length)

  const { name, extension } = match.groups

  return `${truncateString(name!, length)}.${extension!}`
}

// modified version of lodash 'snakeCase' function
// - doesn't change dots with underscores
// - doesn't add underscores before numbers
const snakeCase = (field: string) => {
  return field
    .split('.')
    .map(part => lodashSnakeCase(part).replace(/_(\d+)(_|$)/g, '$1$2'))
    .join('.')
}

const camelCase = (field: string) => {
  return field
    .split('.')
    .map(part => lodashCamelCase(part))
    .join('.')
}

const isLetter = (symbol: unknown) =>
  typeof symbol === 'string' ? /^[A-Za-z]+$/.test(symbol) : false

const falselyStrings = ['0', 'f', 'F', 'false', 'FALSE', 'off', 'OFF']

const isStringTruthy = (value: unknown) =>
  typeof value === 'string' && !falselyStrings.includes(value)

export { truncateString, truncateFilename, snakeCase, camelCase, isLetter, isStringTruthy }
