// Value format should match this list https://en.wikipedia.org/wiki/List_of_HTTP_header_fields#Standard_request_fields
export const HEADERS = {
  ACCEPT_LANGUAGE: 'Accept-Language',
  CACHE_CONTROL: 'Cache-Control',
  CONTENT_TYPE: 'Content-Type',
  COOKIE: 'Cookie',
  PRAGMA: 'Pragma',
  REFERER: 'Referer',
  SET_COOKIE: 'Set-Cookie',
  USER_AGENT: 'User-Agent',
  X_ANON_ID: 'X-Anon-Id',
  X_CONTENT_ONLY: 'X-Content-Only',
  X_CSRF_TOKEN: 'X-CSRF-Token',
  X_DATADOME: 'X-Datadome',
  X_DATADOME_CID: 'X-Datadome-Cid',
  X_DD_B: 'X-Dd-B',
  X_DEBUG_PIN: 'X-Debug-Pin',
  X_FORCED_LOCALE: 'X-Forced-Locale',
  X_FORWARDED_FOR: 'X-Forwarded-For',
  X_FORWARDED_HOST: 'X-Forwarded-Host',
  X_FORWARDED_PROTO: 'X-Forwarded-Proto',
  X_INTERNAL_FORWARDED_PATH: 'X-Internal-Forwarded-Path',
  X_INVOKE_OUTPUT: 'X-Invoke-Output',
  X_NEXT_APP: 'X-Next-App',
  X_NONCE_AUTHENTICATION: 'X-Nonce-Authentication',
  X_PAGE_ID: 'X-Page-Id',
  X_REQUEST_ID: 'X-Request-ID',
  X_SCREEN: 'X-Screen',
  X_USER_LOCALE: 'X-User-Locale',
  X_VINTED_IN_APP: 'X-Vinted-In-App',
  X_V_UDT: 'X-V-UDT',
  X_INTERNAL_URL: 'X-Internal-Url',
} as const

export const NEXT_HEADERS = Object.fromEntries(
  Object.entries(HEADERS).map(([key, value]) => [key, value.toLowerCase()]),
) as {
  [K in keyof typeof HEADERS]: Lowercase<(typeof HEADERS)[K]>
}

export const SRE_BLACKBOX_PROBE_USER_AGENT = 'Vinted blackbox exporter'

export const NEXT_CORE_WHITELISTED_HEADERS = {
  'x-tester': 'X-Tester', // used in e2e, should be passed to core to change some behavior for test users
  'x-skip-allowed-overrides-check': 'X-Skip-Allowed-Overrides-Check', // used in e2e, should be passed to abs service to skip cache for test users
  'x-force-country': 'X-Force-Country', // used in e2e, should be passed to core to change country when registering user
  'user-agent': 'User-Agent',
  'x-forwarded-for': 'X-Forwarded-For',
  'x-forwarded-host': 'X-Forwarded-Host',
  'x-request-id': 'X-Request-Id',
  'x-device-model': 'X-Device-Model',
  'x-device-uuid': 'X-Device-UUID',
  'x-cf-client-score': 'X-CF-Client-Score',
  'x-v-udt': 'X-V-UDT',
  'x-ja3': 'X-JA3',
  'x-vinted-in-app': 'X-VINTED-IN-APP',
  authorization: 'Authorization',
  'x-external-prober-auth': 'X-External-Prober-Auth',
}
