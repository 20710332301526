import { TwoFactorVerificationDto } from 'types/dtos'
import { TwoFactorVerificationModel } from 'types/models'

export const transformTwoFactorVerification = (
  payload: TwoFactorVerificationDto,
): TwoFactorVerificationModel => ({
  id: payload.id,
  askUser2fa: payload.ask_user_2fa,
  showResendOption: payload.show_resend_option,
  userMaskedPhoneNumber: payload.masked_phone_number,
  userMaskedInfo: payload.user_masked_info,
  nextResendAvailableIn: payload.next_resend_available_in,
})
