import { CurrencyConversionDto, CurrencyConversionWithMoneyObjectDto } from 'types/dtos'
import { CurrencyConversionModel, CurrencyConversionWithMoneyObjectModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformCurrencyConversionDto = ({
  seller_currency,
  seller_price,
  seller_shipping_price,
  fx_base_amount,
  fx_rounded_rate,
  fx_markup_rate,
  buyer_currency,
}: CurrencyConversionDto): CurrencyConversionModel => {
  return {
    sellerPrice: seller_price,
    sellerShippingPrice: seller_shipping_price,
    sellerCurrency: seller_currency,
    buyerCurrency: buyer_currency,
    fxRoundedRate: fx_rounded_rate,
    fxBaseAmount: fx_base_amount,
    fxMarkupRate: fx_markup_rate,
  }
}

export const transformCurrencyConversionWithMoneyObjectDto = ({
  seller_price,
  shipping_price,
  fx_base_amount,
  fx_rounded_rate,
  fx_markup_rate,
  buyer_currency,
}: CurrencyConversionWithMoneyObjectDto): CurrencyConversionWithMoneyObjectModel => {
  return {
    sellerPrice: transformCurrencyAmountDto(seller_price),
    sellerShippingPrice: shipping_price ? transformCurrencyAmountDto(shipping_price) : null,
    buyerCurrency: buyer_currency,
    fxRoundedRate: fx_rounded_rate,
    fxBaseAmount: fx_base_amount,
    fxMarkupRate: fx_markup_rate,
  }
}
