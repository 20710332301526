import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { AddressModel } from 'types/models'

import { stateName } from './constants'
import { State, BusinessAddressesModel } from './types'

export const initialState: State = {
  isBusinessAddressModalOpen: false,
  isReturnAddressModalOpen: false,
  isReturnAddressErrorShown: false,
  isUsingTheSameAddress: true,
  businessAddress: null,
  returnAddress: null,
}

const toggleBusinessAddressModal: CaseReducer<State> = draft => {
  draft.isBusinessAddressModalOpen = !draft.isBusinessAddressModalOpen
}

const toggleReturnAddressModal: CaseReducer<State> = draft => {
  draft.isReturnAddressModalOpen = !draft.isReturnAddressModalOpen
}

const toggleUsingTheSameAddress: CaseReducer<State> = draft => {
  draft.isUsingTheSameAddress = !draft.isUsingTheSameAddress
}

const setReturnAddressErrorShown: CaseReducer<
  State,
  PayloadAction<{ isReturnAddressErrorShown: boolean }>
> = (draft, action) => {
  draft.isReturnAddressErrorShown = action.payload.isReturnAddressErrorShown
}

const setBusinessAddress: CaseReducer<State, PayloadAction<AddressModel>> = (draft, action) => {
  draft.businessAddress = action.payload
}

const setReturnAddress: CaseReducer<State, PayloadAction<AddressModel | null>> = (
  draft,
  action,
) => {
  draft.returnAddress = action.payload
}

const fetchBusinessAddressesComplete: CaseReducer<State, PayloadAction<BusinessAddressesModel>> = (
  draft,
  action,
) => {
  const { businessAddress, returnAddress, isUsingTheSameAddress } = action.payload

  draft.businessAddress = businessAddress
  draft.returnAddress = returnAddress
  draft.isUsingTheSameAddress = isUsingTheSameAddress
}

const businessAddressesSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    toggleBusinessAddressModal,
    toggleReturnAddressModal,
    toggleUsingTheSameAddress,
    setReturnAddressErrorShown,
    setBusinessAddress,
    setReturnAddress,
    fetchBusinessAddressesComplete,
  },
})

export const { actions } = businessAddressesSlice
export const plug = { [stateName]: businessAddressesSlice.reducer }
export default businessAddressesSlice.reducer
