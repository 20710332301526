import { createAction } from '@reduxjs/toolkit'

import { BusinessRegistrationModel } from 'types/models'

import { stateName } from './constants'

type SubmitRegistrationActionProps = {
  registrationFields: Partial<BusinessRegistrationModel>
  successCallback: () => void
}

type UpdateRegistrationActionProps = {
  registrationFields: Partial<BusinessRegistrationModel>
  successCallback: () => void
  validate: boolean
}

export const clearRegistrationDefaultValues = createAction(
  `${stateName}/clearRegistrationDefaultValues`,
)

export const loadRegistrationDefaultValues = createAction(
  `${stateName}/loadRegistrationDefaultValues`,
)

export const submitRegistration = createAction(
  `${stateName}/submitRegistration`,
  ({ registrationFields, successCallback }: SubmitRegistrationActionProps) => {
    return {
      payload: {
        registrationFields,
      },
      meta: {
        successCallback,
      },
    }
  },
)

export const updateRegistration = createAction(
  `${stateName}/updateRegistration`,
  ({ registrationFields, successCallback, validate }: UpdateRegistrationActionProps) => {
    return {
      payload: {
        registrationFields,
      },
      meta: {
        successCallback,
        validate,
      },
    }
  },
)

export const updateLegalEntity = createAction<{ legalCode: string }>(
  `${stateName}/updateLegalEntity`,
)

export const fetchFormConfigurationRequest = createAction(
  `${stateName}/fetchFormConfigurationRequest`,
)

export const fetchFormConfigurationFailure = createAction(
  `${stateName}/fetchFormConfigurationFailure`,
)

export const submitKybFormRequest = createAction(`${stateName}/submitKybFormRequest`)
