import { createSelector } from 'reselect'
import { compact } from 'lodash'

import { PaymentMethodModel, CurrencyAmountModel } from 'types/models'
import { PayInMethodCode } from 'constants/pay-in-method'
import { PhoneRequirement, FieldName as ShippingContactFieldName } from 'constants/shipping-contact'
import { UiState } from 'constants/ui'
import { FieldName, DeliveryType } from 'constants/checkout'

import { AllState, CheckoutState } from './types'
import { stateName } from './constants'
import { selectors as shippingSelectors } from './shipping'

const checkoutState = (state: AllState): CheckoutState => state[stateName]
const localState = (state: AllState) => checkoutState(state).checkout

export const getCheckoutData = (state: AllState) => localState(state).checkoutData

export const getTransactionId = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.id || null,
)
export const getTransactionChecksum = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.checksum || '',
)

export const getSelectedPayInMethod = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.payInMethod || null,
)
export const getPayInMethodsConfig = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.payInMethodsConfig || null,
)

export const getBuyerPhoneNumber = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.phoneNumber,
)
export const getPricing = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.pricing || null,
)
export const getCurrencyConversion = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.pricing.conversion || null,
)
export const getRecommendedPayInMethod = createSelector(
  getPayInMethodsConfig,
  payInMethodsConfig =>
    payInMethodsConfig?.recommended ? payInMethodsConfig.recommended[0] : null,
)
export const getPayInMethodIncentive = createSelector(
  getPayInMethodsConfig,
  payInMethodsConfig => payInMethodsConfig?.incentive || null,
)
export const getPayInMethodPromotion = createSelector(
  getPayInMethodsConfig,
  payInMethodsConfig => payInMethodsConfig?.promotion || null,
)
export const getIsAddCardRecommendationEnabled = createSelector(
  getPayInMethodIncentive,
  getRecommendedPayInMethod,
  (payInMethodIncentive, recommendedPayInMethod) => {
    if (payInMethodIncentive) return false
    if (!recommendedPayInMethod) return false

    const { payInMethod, externalCard } = recommendedPayInMethod

    return payInMethod.code === PayInMethodCode.CreditCard && !externalCard
  },
)
export const getIsUseExistingCardRecommendationEnabled = createSelector(
  getPayInMethodIncentive,
  getRecommendedPayInMethod,
  (payInMethodIncentive, recommendedPayInMethod) => {
    if (payInMethodIncentive) return false
    if (!recommendedPayInMethod) return false

    const { payInMethod, externalCard } = recommendedPayInMethod

    return payInMethod.code === PayInMethodCode.CreditCard && !!externalCard
  },
)
export const getIsWalletRestricted = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.isWalletRestricted ?? null,
)

export const getIsSpecialVerificationRequired = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.isSpecialVerificationRequired ?? null,
)

export const getSelectedCardId = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.creditCardId || null,
)
export const getSelectedCard = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.card || null,
)
export const getShipmentPrice = createSelector(
  getCheckoutData,
  (checkoutData): CurrencyAmountModel | null => checkoutData?.pricing.shipping.finalPrice || null,
)
export const getOfflineVerificationService = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.services.offlineVerification,
)

export const getElectronicsVerificationService = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.services.electronicsVerification,
)

export const getIsPaymentProcessing = (state: AllState) => localState(state).isPaymentProcessing
export const getTwoFactorVerification = (state: AllState) => localState(state).twoFactorVerification
export const getFormState = (state: AllState) => localState(state).formState
export const getPayment = (state: AllState) => localState(state).payment
export const getPaymentError = (state: AllState) => localState(state).formState.errors.paymentError
export const getUiState = (state: AllState) => localState(state).ui
export const getBlikPaymentAuth = (state: AllState) => localState(state).blikPaymentAuth
export const getPaymentAuthAction = (state: AllState) => localState(state).paymentAuthAction
export const getIsCvvHandlingFlowEnabled = (state: AllState) =>
  localState(state).cvvHandlingData.isCvvHandlingFlowEnabled
export const getPayrailsInitData = (state: AllState) =>
  localState(state).cvvHandlingData.payrailsInitData
export const getEncryptedCvv = (state: AllState) => localState(state).cvvHandlingData.encryptedCvv
export const getCvvHandlingError = (state: AllState) =>
  localState(state).formState.errors.cvvHandlingError

export const getIsPaymentAvailable = createSelector(
  getCheckoutData,
  checkoutData => !!checkoutData?.paymentAvailable,
)
export const getOrderPrice = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.pricing.base.finalPrice || null,
)
export const getOrderItems = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.items || [],
)
export const getIsBundleOrder = createSelector(getCheckoutData, checkoutData => {
  if (checkoutData?.items) return checkoutData.items.length > 1

  return false
})

export const getConfiguration = createSelector(localState, ({ configuration }) => configuration)
export const getThreeDS2Config = createSelector(
  getConfiguration,
  config => config?.threeDS2 || null,
)

export const getFieldError = (state: AllState, fieldName: FieldName) =>
  localState(state).ui.errors.byName[fieldName]

export const getFieldErrors = createSelector(getUiState, ({ errors }) => errors.names)

export const getIsSellerBusinessUser = createSelector(
  getCheckoutData,
  checkoutData => !!checkoutData?.seller.isBusiness,
)

export const getAddress = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.buyer.shipmentToAddress,
)

export const getIsTwoFactorVerificationNeeded = createSelector(
  getTwoFactorVerification,
  twoFactorVerification => !!twoFactorVerification,
)

export const getHasChecksumError = createSelector(
  getFormState,
  ({ errors }) => errors.hasChecksumError,
)

export const getHasShippingDiscountError = createSelector(
  getFormState,
  ({ errors }) => errors.hasShippingDiscountError,
)

export const getIsPaymentProcessingTakesTooLong = createSelector(
  getFormState,
  ({ errors }) => errors.isPaymentProcessingTakesTooLong,
)

export const getIsPaymentFailedAfterRedirect = createSelector(
  getFormState,
  ({ errors }) => errors.isPaymentFailedAfterRedirect,
)

export const getIsAddressCompleted = createSelector(
  getAddress,
  getFieldErrors,
  (address, fieldErrors) => {
    if (fieldErrors.includes(FieldName.Address)) return false

    return !!address
  },
)

export const getIsShipmentCompleted = createSelector(
  shippingSelectors.getCheckoutShippingService,
  shippingSelectors.getIsShipmentActionable,
  (checkoutShippingService, isShipmentActionable) => {
    if (!isShipmentActionable) return true

    const { selectedDeliveryType, deliveryTypes } = checkoutShippingService || {}

    if (!deliveryTypes?.pickup && selectedDeliveryType === DeliveryType.PickupPoint) return false

    return true
  },
)

export const getIsDeliveryTypesInState = createSelector(
  shippingSelectors.getDeliveryTypes,
  shippingSelectors.getSelectedDeliveryType,
  (deliveryTypes, selectedDeliveryType) => !!deliveryTypes && !!selectedDeliveryType,
)

export const getBlikPaymentCode = createSelector(
  getBlikPaymentAuth,
  blikPaymentAuth => blikPaymentAuth?.blikCode || null,
)

export const getIsBlikAuthRequired = createSelector(
  getCheckoutData,
  getSelectedPayInMethod,
  (checkoutData, selectedPayInMethod) => {
    if (selectedPayInMethod?.code !== PayInMethodCode.BlikDirect) return false
    if (!checkoutData) return false

    const orderTotal = parseFloat(checkoutData.pricing.payment.payIn.amount)

    return orderTotal > 0
  },
)

export const getPaymentMethod = createSelector(
  getSelectedCard,
  getSelectedPayInMethod,
  (card, selectedPayInMethod): PaymentMethodModel | null => {
    if (!selectedPayInMethod) return null

    const { id, code, note, icon, translatedName, requiresCreditCard, methodChangePossible } =
      selectedPayInMethod

    if (code === PayInMethodCode.ApplePay) return null
    if (code === PayInMethodCode.GooglePay) return null

    if (!requiresCreditCard)
      return {
        id,
        code,
        note,
        isCard: false,
        brand: icon,
        name: translatedName,
        methodChangePossible,
      }

    if (!card) return null

    return {
      id,
      code,
      cardId: card.id,
      isCard: true,
      brand: card.brand,
      lastFour: card.lastFour,
      isExpired: card.expired,
      isSingleUse: card.isSingleUse,
      methodChangePossible,
    }
  },
)

export const getFormattedAddress = createSelector(getAddress, address =>
  compact([
    address?.line1,
    address?.line2,
    address?.city,
    address?.state,
    address?.postalCode,
  ]).join(' '),
)

export const getCheckoutDataUiState = createSelector(getUiState, ({ checkoutData }) => checkoutData)

export const getIsOfflineVerificationCheckDisabled = createSelector(
  getIsPaymentProcessing,
  getCheckoutDataUiState,
  (isPaymentProcessing, transactionUiState) =>
    isPaymentProcessing || transactionUiState === UiState.Pending,
)

export const getIsShippingContactCompleted = createSelector(
  shippingSelectors.getBuyerPhoneRequirement,
  getBuyerPhoneNumber,
  (requirement, buyerPhoneNumber) =>
    !(requirement === PhoneRequirement.Mandatory && !buyerPhoneNumber),
)

export const getIsShippingContactDisabled = createSelector(
  getIsShipmentCompleted,
  shippingSelectors.getBuyerPhoneRequirement,
  (isShipmentCompleted, requirement) => {
    if (!isShipmentCompleted) return true
    if (!requirement || requirement === PhoneRequirement.Disabled) return true

    return false
  },
)

export const getIsPaymentCompleted = createSelector(
  getSelectedCard,
  getSelectedPayInMethod,
  (card, selectedPayInMethod) => {
    if (card && !card.expired) return true
    if (!selectedPayInMethod) return false

    return !selectedPayInMethod.requiresCreditCard
  },
)

export const getIsFormCompleted = createSelector(
  getIsPaymentCompleted,
  getIsAddressCompleted,
  getIsShipmentCompleted,
  getIsShippingContactCompleted,
  getIsDeliveryTypesInState,
  getIsPaymentAvailable,
  (...args) => args.every(Boolean),
)

export const getIsCheckoutUiLoaded = createSelector(
  getConfiguration,
  getCheckoutDataUiState,
  shippingSelectors.getDeliveryTypesUiState,
  (configuration, transactionUiState, deliveryTypesUiState) =>
    !!configuration &&
    transactionUiState === UiState.Success &&
    deliveryTypesUiState === UiState.Success,
)

export const getShippingContactFieldError = (state: AllState, field: ShippingContactFieldName) =>
  localState(state).formState.errors.shippingContactErrors.find(error => error.field === field) ||
  null
