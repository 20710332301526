import type { IncomingHttpHeaders } from 'http'

const CAPTURE_HEADER_PREFIX = 'captured' // must match the value in next/server-preload/prometheus.js

export function getCapturedHeader(
  headers: IncomingHttpHeaders,
  header: string,
): string | undefined {
  const name = `${CAPTURE_HEADER_PREFIX}-${header}`

  return headers[name]?.toString()
}

export function clearCapturedHeaders(headers: IncomingHttpHeaders) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(headers)) {
    if (key.startsWith(CAPTURE_HEADER_PREFIX)) {
      // eslint-disable-next-line no-param-reassign
      delete headers[key]
    }
  }
}
