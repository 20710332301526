import { flatMap, shuffle } from 'lodash'

import { CatalogModel, ListerActivationBannerModel } from 'types/models'

export const getRandomizedListerActivationBanner = (
  banners: Array<ListerActivationBannerModel>,
): ListerActivationBannerModel => {
  const totalBanners = banners.length

  const targetBannerIndex = Math.floor(Math.random() * totalBanners)
  const targetBanner = banners[targetBannerIndex]!

  return {
    ...targetBanner,
    imageUrls: shuffle(targetBanner.imageUrls),
  }
}

export const findListerActivationBannerFromCatalogIds = (
  catalogIds: Array<number>,
  banners: Array<ListerActivationBannerModel>,
) => {
  const bannerCatalogIds = banners.map(({ catalogId }) => catalogId)

  const targetCatalogId = catalogIds.find(id =>
    bannerCatalogIds.find(catalogId => catalogId === id),
  )

  if (!targetCatalogId) return undefined

  return banners.find(({ catalogId }) => catalogId === targetCatalogId)
}

export const getParentListerActivationBanner = (
  catalogs: Array<CatalogModel>,
  catalogMap: Record<number, CatalogModel>,
  banners: Array<ListerActivationBannerModel>,
) => {
  const catalogIdsWithParents = flatMap(catalogs, catalog => {
    const catalogParentIds: Array<number> = []

    let targetCatalog = catalog
    while (targetCatalog) {
      catalogParentIds.push(targetCatalog.id)
      targetCatalog = catalogMap[targetCatalog.parentId]!
    }

    return catalogParentIds
  })

  const targetBanner = findListerActivationBannerFromCatalogIds(catalogIdsWithParents, banners)

  return targetBanner ? [targetBanner] : undefined
}

export const getRootCatalogListerActivationBanners = (
  catalogs: Array<CatalogModel>,
  banners: Array<ListerActivationBannerModel>,
) => {
  const rootCatalogs = catalogs.filter(({ depth }) => depth === 0)

  for (let index = 0; rootCatalogs[index]; index += 1) {
    const childrenIds = rootCatalogs[index]!.catalogIds

    const rootBanners = banners.filter(({ catalogId }) => childrenIds.includes(catalogId))

    if (rootBanners.length) return rootBanners
  }

  return undefined
}
