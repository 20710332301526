import { CreditCardRegistrationPrepareModel, PayrailsInitDataModel } from 'types/models'

import { CreditCard } from '../types'
import { TokenizeResult, TokenizeStatus } from './types'

function load() {
  // Payrails does not require loading of resources
}

async function tokenize(
  card: CreditCard,
  registrationPrepare: CreditCardRegistrationPrepareModel,
): Promise<TokenizeResult> {
  try {
    if (!registrationPrepare.initializationData) {
      return { status: TokenizeStatus.LibraryNotLoaded }
    }

    const { PayrailsCSE } = await import('@payrails/web-cse')

    const cse = PayrailsCSE.init(registrationPrepare.initializationData)

    const transformedCard = {
      holderName: card.name,
      cardNumber: card.cardNumber,
      expiryMonth: card.expirationMonth.toString(),
      expiryYear: card.expirationYear.toString(),
      securityCode: card.cvc,
    }

    const options = {
      storeInstrument: true,
    }

    const tokenizedCardResponse = await cse.tokenize(transformedCard, options)
    const tokenizedCard = await tokenizedCardResponse.json()

    if ('errors' in tokenizedCard || !tokenizedCard?.id) {
      return { status: TokenizeStatus.ProviderError }
    }

    return {
      status: TokenizeStatus.Ok,
      encryptedCard: tokenizedCard.id,
    }
  } catch {
    return { status: TokenizeStatus.ProviderError }
  }
}

export async function encryptCvv(code: string, payrailsInitData: PayrailsInitDataModel) {
  try {
    const { PayrailsCSE } = await import('@payrails/web-cse')

    const cse = PayrailsCSE.init(payrailsInitData)

    const params = {
      card: {
        securityCode: code,
      },
    }

    const encryptedCvvResponse = await cse.encryptCardData(params)

    if (!encryptedCvvResponse) {
      return { status: TokenizeStatus.ProviderError }
    }

    return {
      encryptedCvv: encryptedCvvResponse,
    }
  } catch {
    return { status: TokenizeStatus.ProviderError }
  }
}

export default {
  load,
  tokenize,
}
