import { PortalMergeDraftItemsReminderDto } from 'types/dtos'
import { PortalMergeDraftItemsReminderModel } from 'types/models'

export const transformPortalMergeDraftItemsReminder = ({
  title,
  subtitle,
  action_title,
  action_url,
}: PortalMergeDraftItemsReminderDto): PortalMergeDraftItemsReminderModel => ({
  title,
  subtitle,
  actionTitle: action_title,
  actionUrl: action_url,
})
