export const stateName = 'items' as const

export const PER_PAGE = 20
export const CATALOG_PER_PAGE = 96
export const CATALOG_FETCH_ITEM_DEBOUNCE_AMOUNT = 100
export const MAX_OWNER_ITEMS_PER_PAGE = 24

export enum ItemUiView {
  UserItems = 'userItems',
  SimilarItems = 'similarItems',
  CatalogItems = 'catalogItems',
}
