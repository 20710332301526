import {
  DynamicFilterDto,
  DynamicFilterOptionDto,
  SearchTranslationsDto,
  SelectedDynamicFilterDto,
} from 'types/dtos/dynamic-filter'
import {
  DynamicFilterModel,
  DynamicFilterOptionModel,
  SearchTranslationsModel,
  SelectedDynamicFilterModel,
} from 'types/models/dynamic-filter'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformSearchTranslations = (
  searchTranslations?: SearchTranslationsDto,
): SearchTranslationsModel | undefined => {
  if (!searchTranslations) return undefined

  return {
    placeholder: searchTranslations.placeholder,
    noResults: {
      title: searchTranslations.no_results?.title,
      body: searchTranslations.no_results?.body,
    },
  }
}

export const transformDynamicFilterOptions = (
  dynamicFilterOptions: Array<DynamicFilterOptionDto> | undefined,
): Array<DynamicFilterOptionModel> => {
  if (!dynamicFilterOptions?.length) return []

  return dynamicFilterOptions.map(
    ({
      id,
      title,
      description,
      type,
      prefix,
      items_count,
      items_count_max,
      options,
    }: DynamicFilterOptionDto): DynamicFilterOptionModel => ({
      id,
      title,
      description,
      type,
      prefix,
      itemsCount: items_count,
      itemsCountMax: items_count_max,
      options: transformDynamicFilterOptions(options),
    }),
  )
}

export const transformDynamicFilter = ({
  id,
  title,
  code,
  display_type,
  selection_type,
  is_selection_highlighted,
  search_translations,
  is_new_filter,
  is_hidden,
  experiment,
  links,
  options,
  minimum_validation,
}: DynamicFilterDto): DynamicFilterModel => ({
  id,
  title,
  type: code,
  displayType: display_type,
  selectionType: selection_type,
  isSelectionHighlighted: is_selection_highlighted,
  searchTranslations: transformSearchTranslations(search_translations),
  isNewFilter: is_new_filter,
  isHidden: is_hidden,
  experiment,
  links,
  minimumValidation: minimum_validation && transformCurrencyAmountDto(minimum_validation),
  options: transformDynamicFilterOptions(options),
})

export const transformDynamicFilters = (
  dynamicFilters: Array<DynamicFilterDto>,
): Array<DynamicFilterModel> => dynamicFilters.map(transformDynamicFilter)

export const transformSelectedDynamicFilter = ({ code, ids }: SelectedDynamicFilterDto) => ({
  type: code,
  ids,
})

export const transformSelectedDynamicFilters = (
  selectedFilters: Array<SelectedDynamicFilterDto>,
): Array<SelectedDynamicFilterModel> => selectedFilters.map(transformSelectedDynamicFilter)

export const transformSelectedDefaultDynamicFilters = (
  selectedDefaultFilters: Array<SelectedDynamicFilterDto>,
): Array<SelectedDynamicFilterModel> => selectedDefaultFilters.map(transformSelectedDynamicFilter)
