import { TermsAndConditionsBannerDto } from 'types/dtos'
import { TermsAndConditionsBannerModel } from 'types/models'

export const transformTermsAndConditions = ({
  title,
  subtitle,
  button_title,
}: TermsAndConditionsBannerDto): TermsAndConditionsBannerModel => ({
  title,
  subtitle,
  buttonTitle: button_title,
})
