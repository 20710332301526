import { CheckoutConfigurationDto, ThreeDS2ConfigurationDto } from 'types/dtos'
import { CheckoutConfigurationModel } from 'types/models'

const transformThreeDS2Config = ({ provider_config, provider }: ThreeDS2ConfigurationDto) => ({
  providerConfig: {
    apiKey: provider_config.api_key,
    site: provider_config.site,
  },
  provider,
})

export const transformCheckoutConfigurationDto = ({
  threeds2,
}: CheckoutConfigurationDto): CheckoutConfigurationModel => {
  return {
    threeDS2: threeds2 ? transformThreeDS2Config(threeds2) : null,
  }
}
