import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AbTestDto } from 'types/dtos'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {}

const setAbTests: CaseReducer<State, PayloadAction<{ abTests: Array<AbTestDto> }>> = (
  draft,
  action,
) => {
  const { abTests } = action.payload

  abTests.forEach(test => {
    draft[test.name] = test
  })
}

const abTestsSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setAbTests,
  },
})

export const { actions } = abTestsSlice
export const plug = { [stateName]: abTestsSlice.reducer }
export default abTestsSlice.reducer
