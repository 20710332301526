import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import type { InAppMessageProvider } from 'libs/common/braze/providers'
import useLocation from 'hooks/useLocation'
import { getBannersUiState } from 'state/banners/selectors'
import { WHITELISTING_RULES } from 'libs/common/braze/constants'
import { UiState } from 'constants/ui'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'

const isPathWhitelisted = (path: string) =>
  WHITELISTING_RULES.some(rule => new RegExp(rule).test(path))

export default function useManageInAppMessage(
  inAppMessageProvider: InAppMessageProvider | undefined,
) {
  const { relativeUrl, urlParams, searchParams } = useLocation()
  const cookies = useCookie()

  const isShowingOnboardingModal = urlParams.onboarding_modal_status === 'shown'
  const isLoadingBannersRedux = useSelector(getBannersUiState) === UiState.Pending
  const isLoadingBannersCookie = cookies.get(cookiesDataByName.banners_ui_state) === UiState.Pending
  const couldOnboardingModalBeShown = isLoadingBannersRedux || isLoadingBannersCookie

  const isProfilePromoShown = !!searchParams.promo_shown

  const isAnyModalShown =
    isShowingOnboardingModal || couldOnboardingModalBeShown || isProfilePromoShown
  const shouldEnable = isPathWhitelisted(relativeUrl) && !isAnyModalShown

  useEffect(() => {
    if (!inAppMessageProvider) return

    if (shouldEnable) {
      inAppMessageProvider.enable()
      inAppMessageProvider.publishStoredMessages()
    } else {
      inAppMessageProvider.disable()
    }
  }, [inAppMessageProvider, shouldEnable])
}
