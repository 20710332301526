import { ErrorItem } from 'types/api'

import { FieldErrorType } from '../types'

export const getFieldErrors = (validationErrors: Array<ErrorItem>) => {
  const errors: FieldErrorType = {
    byName: {},
    names: [],
  }

  validationErrors.forEach(error => {
    errors.byName[error.field] = error.value
  })

  errors.names = validationErrors.map(error => error.field)

  return errors
}
