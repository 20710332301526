import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { union } from 'lodash'

import { UiState } from 'constants/ui'
import { ErrorItem, GetSimilarSoldItemsArgs } from 'types/api'
import { ItemDto, CatalogItemDto } from 'types/dtos'
import { initialEntityStructure } from 'libs/utils/redux'
import { ProductItemModel } from 'types/models'

import { State, GetCatalogItems, ItemPayload, ItemView } from './types'
import { ItemUiView, CATALOG_PER_PAGE, stateName } from './constants'

export const initialState: State = {
  [ItemUiView.UserItems]: {
    ...initialEntityStructure,
    uiState: UiState.Idle,
    errors: null,
    currentPage: 0,
    totalPages: 0,
    totalEntries: 0,
    endReached: false,
  },
  [ItemUiView.SimilarItems]: {
    ...initialEntityStructure,
    uiState: UiState.Idle,
    errors: null,
  },
  [ItemUiView.CatalogItems]: {
    ...initialEntityStructure,
    uiState: UiState.Idle,
    errors: null,
    currentPage: 0,
    totalPages: 0,
    totalEntries: 0,
    perPage: CATALOG_PER_PAGE,
    time: null,
    endReached: false,
  },
}

const startLoad =
  <P>(view: ItemUiView): CaseReducer<State, PayloadAction<P>> =>
  draft => {
    draft[view].uiState = UiState.Pending
  }

const loadItems =
  <T extends ProductItemModel | ItemDto | CatalogItemDto, P extends ItemPayload<T>>(
    view: ItemUiView,
    shouldReplace?: boolean,
  ): CaseReducer<State, PayloadAction<P>> =>
  (draft, action) => {
    const { items, pagination, itemParamsById } = action.payload

    draft[view].uiState = UiState.Success

    if (pagination) {
      draft[view].currentPage = pagination.current_page
      draft[view].totalPages = pagination.total_pages
      draft[view].totalEntries = pagination.total_entries
      draft[view].perPage = pagination.per_page
      draft[view].time = pagination.time
      draft[view].endReached = pagination.current_page >= pagination.total_pages
      draft[view].nextPageToken = pagination.next_page_token
    }

    const newIds: Array<number> = []
    items.forEach(item => {
      newIds.push(item.id)
      draft[view].byId[item.id] = item
    })

    draft[view].searchParams = itemParamsById
    draft[view].errors = null
    draft[view].ids = shouldReplace ? newIds : union(draft[view].ids, newIds)
  }

const setItems =
  <P extends ProductItemModel | ItemDto | CatalogItemDto>(
    view: ItemUiView,
  ): CaseReducer<State, PayloadAction<{ items: Array<P> }>> =>
  (draft, action) => {
    const { items } = action.payload

    items.forEach(item => {
      draft[view].byId[item.id] = item
    })
  }

const loadFail =
  (view: ItemUiView): CaseReducer<State, PayloadAction<{ errors: Array<ErrorItem> }>> =>
  (draft, action) => {
    const { errors } = action.payload

    draft[view].uiState = UiState.Failure
    draft[view].errors = errors
  }

const resetViewState =
  (view: ItemUiView): CaseReducer<State> =>
  draft => {
    // @ts-expect-error avoiding type narrowing
    draft[view] = initialState[view]
  }

const setPage =
  (view: ItemUiView): CaseReducer<State, PayloadAction<{ page: number }>> =>
  (draft, action) => {
    const { page } = action.payload

    draft[view].currentPage = page
  }

const setPerPage =
  (view: ItemUiView): CaseReducer<State, PayloadAction<{ perPage: number }>> =>
  (draft, action) => {
    const { perPage } = action.payload

    draft[view].currentPage = 1
    draft[view].perPage = perPage
  }

const setPagination =
  (
    view: ItemUiView,
  ): CaseReducer<
    State,
    PayloadAction<{
      time: number | undefined
      perPage: number | undefined
      page: number | undefined
    }>
  > =>
  (draft, action) => {
    const { time, page, perPage } = action.payload

    draft[view].time = time
    draft[view].currentPage = page
    draft[view].perPage = perPage
  }

const setCatalogState: CaseReducer<State, PayloadAction<Partial<ItemView>>> = (draft, action) => {
  draft.catalogItems = { ...draft.catalogItems, ...action.payload }
}

const itemSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getCatalogItemsRequest: {
      reducer: startLoad<GetCatalogItems>(ItemUiView.CatalogItems),
      prepare: (payload: GetCatalogItems, meta: { isPaginationEvent: boolean }) => ({
        payload,
        meta,
      }),
    },
    getSimilarItemsRequest: startLoad<GetSimilarSoldItemsArgs>(ItemUiView.SimilarItems),

    getSimilarItemsSuccess: loadItems<ItemDto, ItemPayload>(ItemUiView.SimilarItems),

    getCatalogItemsFailure: loadFail(ItemUiView.CatalogItems),
    getSimilarItemsFailure: loadFail(ItemUiView.SimilarItems),

    resetSimilarItems: resetViewState(ItemUiView.SimilarItems),
    setCatalogItemsPage: setPage(ItemUiView.CatalogItems),
    setCatalogItemsPerPage: setPerPage(ItemUiView.CatalogItems),
    setCatalogItemsPagination: setPagination(ItemUiView.CatalogItems),

    setUserItems: setItems<ProductItemModel>(ItemUiView.UserItems),
    setCatalogItems: setItems<CatalogItemDto>(ItemUiView.CatalogItems),

    setCatalogState,
  },
})

export const actions = {
  ...itemSlice.actions,
}
export const plug = { [stateName]: itemSlice.reducer }
export default itemSlice.reducer
