'use client'

import { createContext, ReactNode, useMemo } from 'react'
import { keyBy } from 'lodash'

import { FeatureSwitchDto } from 'types/dtos'

type FeatureSwitchesProviderProps = {
  children: ReactNode
  featureSwitches: Array<FeatureSwitchDto>
}

export type FeatureSwitches = Record<string, FeatureSwitchDto>

export const FeatureSwitchesContext = createContext<FeatureSwitches>({})

const FeatureSwitchesProvider = ({ featureSwitches, children }: FeatureSwitchesProviderProps) => {
  const featureSwitchesObject = useMemo(
    () => keyBy(featureSwitches, featureSwitch => featureSwitch.name),
    [featureSwitches],
  )

  return (
    <FeatureSwitchesContext.Provider value={featureSwitchesObject}>
      {children}
    </FeatureSwitchesContext.Provider>
  )
}

export default FeatureSwitchesProvider
