import { compact, isEmpty, uniqBy, maxBy } from 'lodash'
import { createSelector } from 'reselect'

import { CatalogModel } from 'types/models'
import { DynamicFilterModel, SelectedDynamicFilterModel } from 'types/models/dynamic-filter'
import { NormalizedData } from 'types/redux'
import { ContentSource } from 'constants/tracking/content-sources'

import { stateName } from './constants'
import { State, AllState, SelectedFilter } from './types'

const localState = (state: AllState): State => state[stateName]

export const getDataDtos = (state: AllState) => localState(state).dtos
export const getData = (state: AllState) => localState(state).data
export const getSearchSession = (state: AllState) => localState(state).searchSession
export const getCatalogTitle = (state: AllState) => localState(state).catalogTitle
export const getCatalogBrandState = (state: AllState) => localState(state).catalogBrand
export const getCatalogBrand = (state: AllState) => localState(state).catalogBrand.brand
export const getCatalogUiState = (state: AllState) => localState(state).data.uiState
export const getConfigurationState = (state: AllState) => localState(state).configuration
export const getConfiguration = (state: AllState) => localState(state).configuration.config
export const getCurrency = (state: AllState) => getConfiguration(state).currency
export const getFilters = (state: AllState) => localState(state).filters
export const getQuery = (state: AllState) => getFilters(state).query
export const getCatalogIds = (state: AllState) => getFilters(state).catalogIds
export const getPriceFrom = (state: AllState) => getFilters(state).priceFrom
export const getPriceTo = (state: AllState) => getFilters(state).priceTo
export const getFilterCurrency = (state: AllState) => getFilters(state).currency
export const getSortBy = (state: AllState) => getFilters(state).sortBy
export const getIsPopularInCatalog = (state: AllState) => getFilters(state).disableSearchSaving
export const getCatalogFrom = (state: AllState) => localState(state).filters.catalogFrom

export const getFiltersChangeCount = (state: AllState) =>
  localState(state).metadata.filtersChangeCount

export const getCatalogById = (state: AllState, id: number): CatalogModel | undefined =>
  getData(state).catalogs.byId[id]

export const getAllCatalogs = (state: AllState) =>
  compact(getData(state).catalogs.ids.map(id => getCatalogById(state, id)))

export const getCatalogMap = (state: AllState) => getData(state).catalogs.byId

export const getSelectedCatalogs = (state: AllState) =>
  compact(getCatalogIds(state).map(catalogId => getCatalogById(state, catalogId)))

export const getSelectedFilters = (state: AllState, includeQuery = true): Array<SelectedFilter> => {
  const query = getQuery(state)
  const catalogs = getSelectedCatalogs(state)
  const priceFrom = getPriceFrom(state)
  const priceTo = getPriceTo(state)
  const selectedFilters: Array<SelectedFilter> = []

  catalogs.forEach(catalog => {
    selectedFilters.push({
      name: 'catalogIds',
      label: catalog.title,
      value: catalog.id,
    })
  })

  if (!isEmpty(query) && includeQuery) {
    selectedFilters.push({
      name: 'query',
      label: String(query),
      value: String(query),
    })
  }

  if (!isEmpty(priceFrom)) {
    selectedFilters.push({
      name: 'priceFrom',
      value: String(priceFrom),
    })
  }

  if (!isEmpty(priceTo)) {
    selectedFilters.push({
      name: 'priceTo',
      value: String(priceTo),
    })
  }

  return selectedFilters
}

export const getSelectedFiltersCount =
  ({ includeQuery = true }) =>
  (state: AllState) =>
    getSelectedFilters(state, includeQuery).length

export const getSelectedFilterNamesWithoutQuery = createSelector(getSelectedFilters, filters =>
  uniqBy(filters, 'name')
    .map(({ name }) => name)
    .filter(name => name !== 'query'),
)

export const getRootCatalogs = createSelector(getAllCatalogs, catalogs =>
  catalogs.filter(catalog => catalog?.depth === 0),
)

export const getSelectedCatalogsWithRoots = createSelector(
  getSelectedCatalogs,
  getRootCatalogs,
  (selectedCatalogs, rootCatalogs) =>
    selectedCatalogs.length ? selectedCatalogs : compact(rootCatalogs),
)

export const getDeepestSelectedCatalog = createSelector(getSelectedCatalogs, selectedCatalogs =>
  maxBy(selectedCatalogs, catalog => catalog.depth),
)

export const getCatalogsForBreadcrumbs = (state: AllState) => {
  const deepestCatalog = getDeepestSelectedCatalog(state)

  if (!deepestCatalog) return []

  let catalog: CatalogModel | undefined = deepestCatalog
  const result: Array<CatalogModel | undefined> = []

  while (catalog) {
    result.push(catalog)

    catalog = getCatalogById(state, Number(catalog.parentId))
  }

  return compact(result.reverse())
}

export const getSubcatalogs = createSelector(
  getDeepestSelectedCatalog,
  getAllCatalogs,
  getRootCatalogs,
  (deepestSelected, allCatalogs, rootCatalogs) => {
    if (deepestSelected) {
      const { catalogIds } = deepestSelected

      return allCatalogs.filter(catalog => catalog?.id && catalogIds.includes(catalog.id))
    }

    return rootCatalogs
  },
)

export const getSearchCorrelationId = (state: AllState) => getSearchSession(state).correlationId
export const getSearchSessionId = (state: AllState) => getSearchSession(state).sessionId
export const getGlobalSearchSessionId = (state: AllState) =>
  getSearchSession(state).globalSearchSessionId
export const getIsSearchSessionStale = (state: AllState) => getSearchSession(state).isStale
export const getCatalogContentSource = createSelector(
  getQuery,
  getIsPopularInCatalog,
  (query, isPopularCatalog) => {
    if (query) return ContentSource.Search
    if (isPopularCatalog) return ContentSource.PopularItems

    return ContentSource.Catalog
  },
)

export const getDynamicFiltersState = (state: AllState) => localState(state).dynamicFilters
export const getDynamicFiltersDataState = createSelector(
  getDynamicFiltersState,
  ({ dynamicFilters }) => dynamicFilters,
)
export const getDynamicFiltersTypes = createSelector(getDynamicFiltersDataState, ({ ids }) => ids)
export const getDynamicFiltersById = createSelector(getDynamicFiltersDataState, ({ byId }) => byId)
export const getDynamicFiltersByType = (type: string) =>
  createSelector<AllState, Record<string, DynamicFilterModel>, DynamicFilterModel | undefined>(
    getDynamicFiltersById,
    byId => byId[type],
  )
export const getDynamicFilters = createSelector(
  getDynamicFiltersTypes,
  getDynamicFiltersById,
  (types, byType) => compact(types.map(type => byType[type])),
)

export const getSelectedDynamicFiltersDataState = createSelector(
  getDynamicFiltersState,
  ({ selectedDynamicFilters }) => selectedDynamicFilters,
)
export const getSelectedDynamicFiltersTypes = createSelector(
  getSelectedDynamicFiltersDataState,
  ({ ids }) => ids,
)
export const getSelectedDynamicFiltersByType = (type: string) =>
  createSelector<
    AllState,
    NormalizedData<SelectedDynamicFilterModel, string>,
    SelectedDynamicFilterModel | undefined
  >(getSelectedDynamicFiltersDataState, ({ byId }) => byId[type])
export const getSelectedDynamicFiltersById = createSelector(
  getSelectedDynamicFiltersDataState,
  ({ byId }) => byId,
)
export const getSelectedDynamicFilters = createSelector(
  getSelectedDynamicFiltersTypes,
  getSelectedDynamicFiltersById,
  (types, byType) => compact(types.map(type => byType[type])),
)

export const getSelectedDefaultDynamicFiltersDataState = createSelector(
  getDynamicFiltersState,
  ({ selectedDefaultFilters }) => selectedDefaultFilters,
)
export const getSelectedDefaultDynamicFiltersTypes = createSelector(
  getSelectedDefaultDynamicFiltersDataState,
  ({ ids }) => ids,
)
export const getSelectedDefultDynamicFiltersByType = (type: string) =>
  createSelector<
    AllState,
    NormalizedData<SelectedDynamicFilterModel, string>,
    SelectedDynamicFilterModel | undefined
  >(getSelectedDefaultDynamicFiltersDataState, ({ byId }) => byId[type])
export const getSelectedDefaultDynamicFiltersById = createSelector(
  getSelectedDefaultDynamicFiltersDataState,
  ({ byId }) => byId,
)
export const getSelectedDefaultDynamicFilters = createSelector(
  getSelectedDefaultDynamicFiltersTypes,
  getSelectedDefaultDynamicFiltersById,
  (types, byType) => compact(types.map(type => byType[type])),
)

export const getTemporarySelectedDynamicFiltersDataState = createSelector(
  getDynamicFiltersState,
  ({ temporarySelectedDynamicFilters }) => temporarySelectedDynamicFilters,
)
export const getTemporarySelectedDynamicFiltersTypes = createSelector(
  getTemporarySelectedDynamicFiltersDataState,
  ({ ids }) => ids,
)
export const getTemporarySelectedDynamicFiltersByType = (type: string) =>
  createSelector<
    AllState,
    NormalizedData<SelectedDynamicFilterModel, string>,
    SelectedDynamicFilterModel | undefined
  >(getTemporarySelectedDynamicFiltersDataState, ({ byId }) => byId[type])

export const getTemporarySelectedDynamicFiltersById = createSelector(
  getTemporarySelectedDynamicFiltersDataState,
  ({ byId }) => byId,
)
export const getTemporarySelectedDynamicFilters = createSelector(
  getTemporarySelectedDynamicFiltersTypes,
  getTemporarySelectedDynamicFiltersById,
  (types, byType) => compact(types.map(type => byType[type])),
)

export const getTemporaryCatalogIds = createSelector(
  getDynamicFiltersState,
  ({ temporaryCatalogIds }) => temporaryCatalogIds,
)

export const getTemporaryPriceRange = createSelector(
  getDynamicFiltersState,
  ({ temporaryPriceRange }) => temporaryPriceRange,
)

export const getFilterSearchQuery = createSelector(
  getDynamicFiltersState,
  ({ filterSearchQuery }) => filterSearchQuery,
)

export const getDynamicFiltersUi = createSelector(getDynamicFiltersState, ({ ui }) => ui)
export const getDynamicFiltersUiErrors = createSelector(getDynamicFiltersUi, ({ errors }) => errors)
export const getDynamicFiltersUiState = createSelector(
  getDynamicFiltersUi,
  ({ uiState }) => uiState,
)
