export const INBOX_URL = '/inbox'
export const NEW_MESSAGE_URL = '/inbox/new'
export const CONVERSATION_URL = (conversationId: number | string): string =>
  `/inbox/${conversationId}`

export const INBOX_ITEM_PATH = (itemId: number) => `/inbox/?item_id=${itemId}`

export const INBOX_ROUTE_PATHS = {
  INBOX: 'inbox/*',
  NEW_MESSAGE: 'new',
  CONVERSATION: ':id',
}
