import { useEffect, useRef } from 'react'

import useLatestCallback from './useLatestCallback'

type Options = { once?: boolean }

/**
 * Attaches an event listener to the window object for the specified event.
 */
export default function useWindowEvent<T extends keyof WindowEventMap>(
  eventName: T,
  handler: (event: WindowEventMap[T]) => void,
  options?: Options,
) {
  const wrappedHandler = useLatestCallback(handler)
  const optionsRef = useRef(options)

  useEffect(() => {
    window.addEventListener(eventName, wrappedHandler, optionsRef.current)

    return () => window.removeEventListener(eventName, wrappedHandler)
  }, [eventName, wrappedHandler])
}
