import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const translateConversationRequest = createAction<{
  conversationId: number | string
  translate: boolean
}>(`${stateName}/translateConversationNewRequest`)

/** @deprecated Use getConversationRequest via useConversationContext to make this request */
export const getConversationRequest = createAction<{
  conversationId: number | string
  fromPostReply?: boolean
}>(`${stateName}/getConversationRequest`)
