import { consentKeyByHostname } from 'constants/consent'
import { Hostname } from 'constants/hostnames'
import { isInternalHostname, normalizeHost } from 'libs/utils/url'
import { OneTrust } from 'types/oneTrust'

export function getConsentKey(hostname: string) {
  const normalizedHostname = normalizeHost(hostname)

  if (isInternalHostname(normalizedHostname)) return consentKeyByHostname[normalizedHostname]

  return consentKeyByHostname[Hostname.FrSandbox]
}

export function initOneTrust(isUserPresent: boolean, anonId?: string) {
  // TODO: Add unit tests
  if (isUserPresent || !anonId || !!window.OneTrust) return

  window.OneTrust = { dataSubjectParams: { id: anonId, isAnonymous: true } } as OneTrust
}
