export const stateName = 'businessAccounts' as const

export const REGISTRATION_FORM_STORAGE_KEY = 'business_registration_form'

export enum BusinessDetailsField {
  LegalCode = 'businessDetails.legalCode',
  LegalName = 'businessDetails.legalName',
  EntityType = 'businessDetails.entityType',
  Vat = 'businessDetails.vat',
  IsVatDisabled = 'businessDetails.isVatDisabled',
}

export enum KybFieldName {
  IdentityDocument = 'identity_document',
  Ubos = 'ubos',
  Nationality = 'nationality',
}
