import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { ReturnShippingModal } from 'constants/return-shipping-option'
import { ExtraServiceCheckoutModal } from 'constants/extra-service'

import { ReturnCurrencyConversionModel, ReturnShipmentOrderModel } from 'types/models'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  returnShipmentOrder: undefined,
  ui: {
    error: null,
    uiState: UiState.Idle,
    activeModal: undefined,
    returnLabelOrder: {
      fetchUiState: UiState.Idle,
      prepareUiState: UiState.Idle,
    },
  },
}

const prepareReturnLabelOrderRequest: CaseReducer<
  State,
  PayloadAction<{
    transactionId: string
    pickupPointCode: string
    packageTypeId: string
    addressId: string
  }>
> = draft => {
  draft.ui.returnLabelOrder.prepareUiState = UiState.Pending
}

const prepareReturnLabelOrderFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.returnLabelOrder.prepareUiState = UiState.Failure
  draft.ui.error = error
}

const prepareReturnLabelOrderSuccess: CaseReducer<
  State,
  PayloadAction<{
    returnShipmentOrder: ReturnShipmentOrderModel
    currencyConversion?: ReturnCurrencyConversionModel | null
  }>
> = (draft, action) => {
  const { returnShipmentOrder, currencyConversion } = action.payload

  draft.ui.returnLabelOrder.prepareUiState = UiState.Success
  draft.ui.error = null
  draft.returnShipmentOrder = returnShipmentOrder

  if (currencyConversion) {
    draft.currencyConversion = currencyConversion
  }
}

const setActiveModal: CaseReducer<
  State,
  PayloadAction<{
    modal: ExtraServiceCheckoutModal | ReturnShippingModal
  }>
> = (draft, action) => {
  const { modal } = action.payload

  draft.ui.activeModal = modal
}

const cancelCheckout: CaseReducer<State> = draft => {
  draft.ui.uiState = UiState.Idle
  draft.returnShipmentOrder = undefined
}

const getReturnLabelOrderRequest: CaseReducer<
  State,
  PayloadAction<{ orderId: number }>
> = draft => {
  draft.ui.returnLabelOrder.fetchUiState = UiState.Pending
  draft.returnShipmentOrder = undefined
}

const getReturnLabelOrderFailure: CaseReducer<State, PayloadAction> = draft => {
  draft.ui.returnLabelOrder.fetchUiState = UiState.Failure
  draft.returnShipmentOrder = undefined
}

const getReturnLabelOrderSuccess: CaseReducer<
  State,
  PayloadAction<{
    returnShipmentOrder: ReturnShipmentOrderModel
  }>
> = (draft, action) => {
  draft.ui.returnLabelOrder.fetchUiState = UiState.Success
  draft.returnShipmentOrder = action.payload.returnShipmentOrder
}

const returnShipmentSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    prepareReturnLabelOrderRequest,
    prepareReturnLabelOrderFailure,
    prepareReturnLabelOrderSuccess,
    cancelCheckout,
    setActiveModal,
    getReturnLabelOrderRequest,
    getReturnLabelOrderFailure,
    getReturnLabelOrderSuccess,
  },
})

export const { actions } = returnShipmentSlice
export const plug = { [stateName]: returnShipmentSlice.reducer }
export default returnShipmentSlice.reducer
