import { USER_THUMB_SIZE, UserThumbnailSize } from 'constants/images'
import { PhotoDto } from 'types/dtos'
import { PhotoModel } from 'types/models'

const getUserThumbnail = (photo: PhotoDto<UserThumbnailSize> | null | undefined): string | null => {
  if (!photo) return null
  if (!photo.thumbnails) return null

  const thumbnail = photo.thumbnails.find(thumb => thumb.type === USER_THUMB_SIZE)

  if (!thumbnail) return null

  return thumbnail.url
}

const getUserPhotoThumbnail = (
  photo: PhotoModel<string> | null | undefined,
  size: UserThumbnailSize = UserThumbnailSize.Default,
): string | null => {
  if (!photo) return null
  if (!photo.thumbnails) return null

  const thumbnail = photo.thumbnails.find(thumb => thumb.type === size)

  if (!thumbnail) return null

  return thumbnail.url
}

const calculateRating = (reputation: number): number => {
  return Math.round(reputation * 5 * 100) / 100
}

const getIsAccountBanPermanent = (accountBanDate: string | null) => {
  if (!accountBanDate) return true

  const banDate = new Date(accountBanDate)

  if (Number.isNaN(banDate.getTime())) return true

  const diffInYears = banDate.getFullYear() - new Date().getFullYear()

  return diffInYears >= 5
}

export { getUserPhotoThumbnail, getUserThumbnail, calculateRating, getIsAccountBanPermanent }
