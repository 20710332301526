import { BREAKPOINT_VALUES_MAP, DEVICE_MAP } from 'constants/breakpoint'
import { BreakpointMap, Breakpoints, Device } from 'types/components'

export const getBreakpointsFromDevice = (device: Device): Breakpoints => {
  const active = DEVICE_MAP[device]

  return {
    active,
    ...Object.fromEntries(active.map(devices => [devices, true])),
  }
}

export const getBreakpointsFromWidth = (width: number): Breakpoints => {
  const active: Array<keyof BreakpointMap> = []

  const breakpoints = Object.entries(BREAKPOINT_VALUES_MAP).reduce(
    (accumulator, [device, breakpoint]) => {
      const match = width >= breakpoint[0] && width <= breakpoint[1]

      if (match) active.push(device as keyof BreakpointMap)

      accumulator[device] = match

      return accumulator
    },
    {},
  )

  return {
    active,
    ...breakpoints,
  }
}
