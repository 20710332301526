import { CookieConsentVersion } from 'constants/consent'
import useSystemConfiguration from 'hooks/useSystemConfiguration'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'

const useConsent = () => {
  const cookies = useCookie()
  const { cookieConsentVersion } = useSystemConfiguration() || {
    cookieConsentVersion: CookieConsentVersion.None,
  }
  const isCookieConsentVersion = cookieConsentVersion !== CookieConsentVersion.None
  const optanonConsentCookie = () => cookies.get(cookiesDataByName.OptanonConsent) || ''

  return {
    cookieConsentVersion,
    isCookieConsentVersion,
    optanonConsentCookie,
  }
}

export default useConsent
