import { ExtraServiceOrderType } from 'constants/extra-service'
import { CreditCardAddReturnResult, CreditCardAddSourceEntityType } from 'constants/credit-card'

import { getClosestValue } from 'libs/utils/number'
import { removeParamsFromQuery, toParams } from 'libs/utils/url'

import { AddCardSourceParams } from 'types/threeDS2'
import { BrowserAttributesModel, CreditCardAddSourceModel } from 'types/models'

const SUPPORTED_SCREEN_DEPTHS = [1, 4, 8, 15, 16, 24, 32, 48] as const

const normalizeScreenColorDepth = (colorDepth: number): number => {
  return getClosestValue(colorDepth, [...SUPPORTED_SCREEN_DEPTHS])
}

export const getBrowserAttributes = (): BrowserAttributesModel => {
  const { screen, navigator, innerHeight } = window

  // Workaround for screenHeight because on some mobile devices screen.height is not provided.
  const fallbackScreenHeight = innerHeight || document.documentElement.clientHeight || 0

  return {
    language: navigator.language,
    javaEnabled: navigator.javaEnabled(),
    colorDepth: normalizeScreenColorDepth(screen.colorDepth),
    screenWidth: screen.width,
    screenHeight: screen.height > 0 ? screen.height : fallbackScreenHeight,
    timezoneOffset: new Date().getTimezoneOffset(),
  }
}

export const isValidCreditCardAddReturnResult = (
  value?: string | Array<string>,
): value is CreditCardAddReturnResult => {
  if (!value) return false
  if (Array.isArray(value)) return false

  return Object.values<string>(CreditCardAddReturnResult).includes(value)
}

const parseCardAddResult = (
  cardAddResult?: string | Array<string>,
): CreditCardAddReturnResult | undefined => {
  return isValidCreditCardAddReturnResult(cardAddResult) ? cardAddResult : undefined
}

const parseCardId = (card_id?: string | Array<string>) => {
  const cardId = Number(card_id)

  if (Number.isNaN(cardId)) return undefined

  return `${cardId}`
}

const parseExtraServiceOrderId = (urlQuery: string, orderType: ExtraServiceOrderType) => {
  const {
    push_up_order_id,
    closet_promotion_order_id,
    direct_donation_order_id,
    featured_collection_order_id,
    return_label_order_id,
  } = toParams(urlQuery)

  switch (orderType) {
    case ExtraServiceOrderType.PushUp:
      return push_up_order_id && Number(push_up_order_id)
    case ExtraServiceOrderType.ClosetPromotion:
      return closet_promotion_order_id && Number(closet_promotion_order_id)
    case ExtraServiceOrderType.DirectDonation:
      return direct_donation_order_id && Number(direct_donation_order_id)
    case ExtraServiceOrderType.FeaturedCollection:
      return featured_collection_order_id && Number(featured_collection_order_id)
    case ExtraServiceOrderType.ReturnLabel:
      return return_label_order_id && Number(return_label_order_id)
    default:
      return undefined
  }
}

export const parseCardAddRedirectUrl = (urlQuery: string) => {
  const { card_add_result, external_card_code } = toParams(urlQuery)

  return {
    cardId: parseCardId(external_card_code),
    cardAddResult: parseCardAddResult(card_add_result),
  }
}

export const parseEscrowPaymentRedirectUrl = (urlQuery: string) => {
  const { after_payment_redirect } = toParams(urlQuery)

  return {
    isRedirectAfterPayment:
      !Array.isArray(after_payment_redirect) && after_payment_redirect?.toLowerCase() === 'true',
  }
}

// TODO delete
export const parseExtraServiceCardAddRedirectUrl = (
  urlQuery: string,
  orderType: ExtraServiceOrderType,
) => {
  const commonCreditCardAddParams = parseCardAddRedirectUrl(urlQuery)

  return {
    ...commonCreditCardAddParams,
    orderId: parseExtraServiceOrderId(urlQuery, orderType),
  }
}

export const getCreditAddCardSource = (
  sourceParams: AddCardSourceParams,
): CreditCardAddSourceModel => {
  switch (sourceParams.type) {
    case 'escrow':
      return {
        entityId: sourceParams.transactionId,
        entityType: CreditCardAddSourceEntityType.Transaction,
      }
    case 'settings':
      return {
        entityType: CreditCardAddSourceEntityType.PaymentSettings,
      }
    default:
      return {
        entityType: CreditCardAddSourceEntityType.Unknown,
      }
  }
}

// TODO delete
export const removeRedirectUrlParams = (relativeUrl: string, urlQuery: string) =>
  removeParamsFromQuery(relativeUrl, urlQuery, [
    'card_id',
    'card_add_result',
    'push_up_order_id',
    'external_card_code',
    'closet_promotion_order_id',
    'direct_donation_order_id',
    'featured_collection_order_id',
    'return_shipment_order_id',
  ])
