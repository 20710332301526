import { call, takeLeading, put } from 'typed-redux-saga'

import { getSystemConfiguration } from 'data/api'
import { transformSystemConfigurationDto } from 'data/transformers/system-configuration'

import { actions } from './slice'

export function* fetchSystemConfigurationRequest() {
  const response = yield* call(getSystemConfiguration)

  if ('errors' in response) {
    yield put(actions.fetchSystemConfigurationFailure())

    return
  }

  const systemConfiguration = yield* call(
    transformSystemConfigurationDto,
    response.system_configuration,
  )

  yield put(actions.fetchSystemConfigurationSuccess({ systemConfiguration }))
}

export default function* saga() {
  yield takeLeading(actions.fetchSystemConfigurationRequest, fetchSystemConfigurationRequest)
}
