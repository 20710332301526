import { FeatureSwitches } from 'contexts/FeatureSwitchesProvider'

export const isFeatureEnabled = (
  featureSwitchName: string,
  featureSwitches: FeatureSwitches,
): boolean => {
  const featureSwitch = featureSwitches[featureSwitchName]

  return featureSwitch?.enabled ?? false
}
