import { SizeDto } from 'types/dtos'
import { SizeModel } from 'types/models'

export const transformSizeDto = (size: SizeDto): SizeModel => {
  const { id, title, order } = size

  return {
    id,
    title,
    order,
    isDefault: !!size.is_default,
    isOther: !!size.is_other,
  }
}

export const transformSizeDtos = (sizes: Array<SizeDto>): Array<SizeModel> =>
  sizes.map(transformSizeDto)
