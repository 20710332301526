import { PromotionalListingBannerInCatalogFeedDto } from 'types/dtos'
import { PromotionalListingBannerInCatalogFeedModel } from 'types/models'

export const transformPromotionalListingBannerInCatalogFeed = ({
  name,
  image_url,
  dark_image_url,
  title,
  body,
  actions,
}: PromotionalListingBannerInCatalogFeedDto): PromotionalListingBannerInCatalogFeedModel => ({
  name,
  imageUrl: image_url,
  darkImageUrl: dark_image_url,
  title,
  body,
  actions,
})
