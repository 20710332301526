import { flatten } from 'lodash'

import { SizeGroupDto } from 'types/dtos'
import { SizeGroupModel, SizeModel } from 'types/models'
import { FilterGroupModel } from 'types/models/filter-group'

import { transformSizeDto, transformSizeDtos } from './size'

export const transformSizeGroupDto = (sizeGroup: SizeGroupDto): SizeGroupModel => {
  const { id, description, sizes } = sizeGroup

  return {
    id,
    description,
    sizes: transformSizeDtos(sizes),
    faqEntryId: sizeGroup.faq_entry_id,
  }
}

export const transformSizeGroupDtos = (
  sizeGroups: Array<SizeGroupDto>,
): { sizeGroups: Array<SizeGroupModel>; sizes: Array<SizeModel> } => ({
  sizeGroups: sizeGroups.map(transformSizeGroupDto),
  sizes: flatten(sizeGroups.map(sizeGroup => sizeGroup.sizes)).map(transformSizeDto),
})

export const transformSizeGroupModelToFilterGroupModel = (
  sizeGroup: SizeGroupModel,
): FilterGroupModel<string> => ({
  id: sizeGroup.id,
  description: sizeGroup.description,
  items: sizeGroup.sizes,
})

export const transformSizeGroupModelsToFilterGroupModels = (
  sizeGroups: Array<SizeGroupModel>,
): Array<FilterGroupModel<string>> => sizeGroups.map(transformSizeGroupModelToFilterGroupModel)
