import { createAction } from '@reduxjs/toolkit'

import { CoordinatesModel } from 'types/models'
import { PickupPointModel } from 'types/models/pickup-point'
import { ShippingOptionModel } from 'types/models/shipping-option'
import { PickupModalElement } from 'constants/checkout'

import { stateName } from '../constants'

export const fetchCountryBoundsRequest = createAction(`${stateName}/fetchCountryBoundsRequest`)

export const fetchNearbyPointsByElementRequest = createAction<{
  coordinates?: CoordinatesModel
  element: PickupModalElement
  shouldLabelNearestPoints?: boolean
}>(`${stateName}/handlePickupModalElementRequest`)

export const trackShippingPointsLoad = createAction<{
  longitude?: number | string
  latitude?: number | string
  pickupPoints?: Array<PickupPointModel>
  shippingOptions?: Array<ShippingOptionModel>
}>(`${stateName}/trackShippingPointsLoad`)

export const fetchNearbyShippingOptionsRequest = createAction<{
  countryCode?: string
  longitude?: number
  latitude?: number
  shouldLabelNearestPoints?: boolean
}>(`${stateName}/fetchNearbyShippingOptionsRequest`)
