import { takeEvery, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import { getSimilarSoldItems as getSimilarItemsRequest } from 'data/api'
import { scrollToTop } from 'libs/utils/window'

import { actions } from './slice'

export function* getSimilarItems({
  payload: { brandId, statusId, catalogId },
}: ReturnType<typeof actions.getSimilarItemsRequest>) {
  const response = yield* call(getSimilarItemsRequest, { brandId, statusId, catalogId })

  if ('errors' in response) {
    yield put(actions.getSimilarItemsFailure({ errors: [] }))

    return
  }

  yield put(
    actions.getSimilarItemsSuccess({
      items: response.items,
    }),
  )
}

export function* paginationChange() {
  yield call(scrollToTop)
}

export default function* saga() {
  yield takeEvery(actions.getSimilarItemsRequest, getSimilarItems)
  yield takeEvery([actions.setCatalogItemsPage, actions.setCatalogItemsPerPage], paginationChange)
}
