import { NpsDto } from 'types/dtos'
import { NpsModel } from 'types/models'

export const transformNps = ({
  question_title,
  open_question_title,
  score_explanation,
}: NpsDto): NpsModel => ({
  questionTitle: question_title,
  openQuestionTitle: open_question_title,
  scoreExplanation: score_explanation,
})
