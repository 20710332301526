'use client'

import { Button, Container, EmptyState, Image } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'

import ErrorHeader from 'components/ErrorHeader'

import StackTrace from '../StackTrace'
import type { FallbackComponentProps } from '../ErrorBoundary'

const AppError = ({ error, errorInfo, handleRetry }: FallbackComponentProps) => {
  const { user } = useSession()
  const translate = useTranslate('error_page')
  const asset = useAsset('/assets/error-page')

  const isGod = user?.is_god

  return (
    <div className="fullpage-layout u-background-white u-fill-viewport">
      <ErrorHeader />
      <main className="site">
        <div className="container">
          <EmptyState
            image={<Image src={asset('404-rack.svg')} />}
            title={translate('client_error.title')}
            body={translate('client_error.body')}
            action={
              <Button styling={Button.Styling.Filled} onClick={handleRetry}>
                {translate('actions.retry')}
              </Button>
            }
          />
          {isGod && (
            <Container>
              <StackTrace error={error} errorInfo={errorInfo} />
            </Container>
          )}
        </div>
      </main>
    </div>
  )
}

export default AppError
