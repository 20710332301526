import { combineReducers, createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { isArray, without } from 'lodash'

import { SortByOption } from 'constants/filter'
import { UiState } from 'constants/ui'
import {
  DynamicFilterModel,
  DynamicFilterOptionModel,
  SelectedDynamicFilterModel,
} from 'types/models/dynamic-filter'
import { BrandModel } from 'types/models'
import { ErrorItem } from 'types/api/response'

import { addNormalizedData } from 'libs/utils/redux'
import { getNewPriceRange } from 'libs/utils/catalog'

import { CURRENCY_FALLBACK, LOCALE_FALLBACK, stateName } from './constants'
import {
  FilterState,
  PriceRange,
  SelectedFilter,
  TargetFilter,
  DataState,
  TransformedApiData,
  SearchSession,
  ApiData,
  State,
  DynamicFiltersState,
  ConfigurationState,
  ConfigurationData,
  CatalogBrandState,
  Metadata,
} from './types'

export const initialFilterState: FilterState = {
  query: null,
  catalogIds: [],
  priceFrom: null,
  priceTo: null,
  currency: null,
  sortBy: null,
  isPopularCatalog: false,
  isPersonalizationDisabled: false,
  catalogFrom: null,
  disableSearchSaving: false,
}

const initialData = {
  byId: {},
  ids: [],
}

export const initialDynamicFilterState: DynamicFiltersState = {
  dynamicFilters: initialData,
  selectedDynamicFilters: initialData,
  selectedDefaultFilters: initialData,
  temporarySelectedDynamicFilters: initialData,
  temporaryCatalogIds: [],
  temporaryPriceRange: {
    priceFrom: null,
    priceTo: null,
  },
  filterSearchQuery: {
    text: null,
    code: null,
  },
  ui: {
    uiState: UiState.Idle,
    errors: [],
  },
}

export const initialDataState: DataState = {
  catalogs: initialData,
  uiState: UiState.Pending,
}

export const initialSearchSessionState: SearchSession = {
  correlationId: '',
  sessionId: '',
  globalSearchSessionId: null,
  isStale: false,
}

export const initialDtoData: ApiData = {
  catalogs: [],
}

export const initialConfigurationState: ConfigurationState = {
  config: {
    currency: CURRENCY_FALLBACK,
    locale: LOCALE_FALLBACK,
  },
  ui: {
    uiState: UiState.Idle,
    errors: [],
  },
}

export const initialCatalogBrandState: CatalogBrandState = {
  brand: undefined,
}

const toggleSortFilterOption: CaseReducer<FilterState, PayloadAction<{ sortBy: SortByOption }>> = (
  draft,
  action,
) => {
  const { sortBy } = action.payload

  draft.sortBy = sortBy
}

const catalogFilterSet: CaseReducer<FilterState, PayloadAction<{ ids: Array<number> }>> = (
  draft,
  action,
) => {
  const { ids } = action.payload

  draft.catalogIds = ids
}

const priceRangeFilterSet: CaseReducer<
  FilterState,
  PayloadAction<{ priceRange: PriceRange; currency: string }>
> = (draft, action) => {
  const {
    priceRange: { priceTo, priceFrom },
    currency,
  } = action.payload
  const newPriceRange = getNewPriceRange(priceFrom, priceTo)

  draft.currency = currency
  draft.priceFrom = newPriceRange.priceFrom
  draft.priceTo = newPriceRange.priceTo
}

const setQuery: CaseReducer<FilterState, PayloadAction<{ query: string }>> = (draft, action) => {
  const { query } = action.payload

  draft.query = query
}

const changeFilters: CaseReducer<
  FilterState,
  PayloadAction<{
    filters: Partial<FilterState>
    selectedDynamicFilters?: Array<SelectedDynamicFilterModel>
  }>
> = (draft, action) => {
  const { filters } = action.payload

  Object.assign(draft, filters)
}

const setInitialFilters: CaseReducer<
  FilterState,
  PayloadAction<{
    filters: Partial<FilterState>
    selectedDynamicFilters?: Array<SelectedDynamicFilterModel>
  }>
> = (draft, action) => {
  const { filters } = action.payload

  Object.assign(draft, filters)
}

const resetFilters = (draft: FilterState, filters: Array<string>) => {
  filters.forEach(filter => {
    draft[filter] = initialFilterState[filter]
  })
}

const removeFilters: CaseReducer<FilterState, PayloadAction<{ filters: Array<string> }>> = (
  draft,
  action,
) => {
  const { filters } = action.payload

  if (!filters.length) return initialFilterState

  return resetFilters(draft, filters)
}

const removeFilter: CaseReducer<
  FilterState,
  PayloadAction<{ filter: SelectedFilter | TargetFilter }>
> = (draft, action) => {
  const { filter: selectedFilter } = action.payload
  const values = draft[selectedFilter.name]
  let newValues

  if (isArray(values) && 'value' in selectedFilter) {
    newValues = without(values, selectedFilter.value)
  } else {
    newValues = initialFilterState[selectedFilter.name]
  }

  // Type assertion needed to avoid type issues, see https://github.com/microsoft/TypeScript/pull/30769
  draft[selectedFilter.name as any] = newValues
}

const filtersSlice = createSlice({
  name: stateName,
  initialState: initialFilterState,
  reducers: {
    // toggle filters reducers
    toggleSortFilterOption,
    // set filters reducers
    catalogFilterSet,
    priceRangeFilterSet,
    setQuery,
    submitSearchQuery: setQuery,
    changeFilters,
    setFilters: changeFilters,
    setInitialFilters,
    // remove filters reducers
    removeFilters,
    removeFilter,
  },
})

const fetchDataRequest: CaseReducer<
  DataState,
  PayloadAction<{
    relativeUrl: string
    searchParams: Record<string, string | Array<string> | undefined>
  }>
> = draft => {
  draft.uiState = UiState.Pending
}

const fetchDataSuccess: CaseReducer<DataState, PayloadAction<TransformedApiData>> = (
  draft,
  action,
) => {
  const { catalogs } = action.payload

  draft.uiState = UiState.Success
  addNormalizedData(draft.catalogs, catalogs)
}

const fetchDataFailure: CaseReducer<DataState> = draft => {
  draft.uiState = UiState.Failure
}

const dataSlice = createSlice({
  name: stateName,
  initialState: initialDataState,
  reducers: {
    fetchDataRequest,
    fetchDataSuccess,
    fetchDataFailure,
  },
})

const setSearchSessionData: CaseReducer<
  SearchSession,
  PayloadAction<{
    searchSessionId: string
    searchCorrelationId: string
    globalSearchSessionId: string | null | undefined
  }>
> = (draft, action) => {
  const { searchCorrelationId, searchSessionId, globalSearchSessionId } = action.payload

  draft.globalSearchSessionId = globalSearchSessionId || null

  if (!searchCorrelationId || !searchSessionId) return

  draft.correlationId = searchCorrelationId
  draft.sessionId = searchSessionId
  draft.isStale = false
}

const setIsStale: CaseReducer<SearchSession> = draft => {
  draft.isStale = true
}

const searchSessionSlice = createSlice({
  name: stateName,
  initialState: initialSearchSessionState,
  reducers: {
    setSearchSessionData,
  },
  extraReducers: {
    [filtersSlice.actions.changeFilters.type]: setIsStale,
  },
})

const setSelectedDynamicFilter: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ selectedDynamicFilter: SelectedDynamicFilterModel }>
> = (draft, action) => {
  const { selectedDynamicFilter } = action.payload
  draft.selectedDynamicFilters.byId[selectedDynamicFilter.type] = selectedDynamicFilter
  if (!draft.selectedDynamicFilters.ids.includes(selectedDynamicFilter.type)) {
    draft.selectedDynamicFilters.ids.push(selectedDynamicFilter.type)
  }
}

const setSelectedDynamicFilters: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ selectedDynamicFilters: Array<SelectedDynamicFilterModel> }>
> = (draft, action) => {
  const { selectedDynamicFilters } = action.payload

  draft.selectedDynamicFilters = { byId: {}, ids: [] }

  selectedDynamicFilters.forEach(selectedDynamicFilter => {
    draft.selectedDynamicFilters.byId[selectedDynamicFilter.type] = selectedDynamicFilter
    draft.selectedDynamicFilters.ids.push(selectedDynamicFilter.type)
  })
}

const removeSelectedDynamicFilterId: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ type: string; id: number }>
> = (draft, action) => {
  const { type, id } = action.payload

  draft.selectedDynamicFilters.byId[type]!.ids = draft.selectedDynamicFilters.byId[
    type
  ]!.ids.filter(item => item !== id)
}

const removeSelectedDynamicFilterIds: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ type: string; ids: Array<number> }>
> = (draft, action) => {
  const { type, ids } = action.payload

  draft.selectedDynamicFilters.byId[type]!.ids = draft.selectedDynamicFilters.byId[
    type
  ]!.ids.filter(item => !ids.includes(item))
}

const removeSelectedDynamicFilters: CaseReducer<DynamicFiltersState> = draft => {
  draft.selectedDynamicFilters.ids.forEach(type => {
    draft.selectedDynamicFilters.byId[type]!.ids = []
  })
}

const removeSelectedDynamicFilter: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ type: string }>
> = (draft, action) => {
  const { type } = action.payload

  delete draft.selectedDynamicFilters.byId[type]
  draft.selectedDynamicFilters.ids = draft.selectedDynamicFilters.ids.filter(id => id !== type)
}

const setTemporarySelectedDynamicFilter: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ temporarySelectedDynamicFilter: SelectedDynamicFilterModel }>
> = (draft, action) => {
  const { temporarySelectedDynamicFilter } = action.payload
  draft.temporarySelectedDynamicFilters.byId[temporarySelectedDynamicFilter.type] =
    temporarySelectedDynamicFilter
  if (!draft.temporarySelectedDynamicFilters.ids.includes(temporarySelectedDynamicFilter.type)) {
    draft.temporarySelectedDynamicFilters.ids.push(temporarySelectedDynamicFilter.type)
  }
}

const setTemporarySelectedDynamicFilters: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ temporarySelectedDynamicFilters: Array<SelectedDynamicFilterModel> }>
> = (draft, action) => {
  const { temporarySelectedDynamicFilters } = action.payload

  draft.temporarySelectedDynamicFilters = { byId: {}, ids: [] }

  temporarySelectedDynamicFilters.forEach(temporarySelectedDynamicFilter => {
    draft.temporarySelectedDynamicFilters.byId[temporarySelectedDynamicFilter.type] =
      temporarySelectedDynamicFilter
    draft.temporarySelectedDynamicFilters.ids.push(temporarySelectedDynamicFilter.type)
  })
}

const removeTemporarySelectedDynamicFilterId: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ type: string; id: number }>
> = (draft, action) => {
  const { type, id } = action.payload

  draft.temporarySelectedDynamicFilters.byId[type]!.ids =
    draft.temporarySelectedDynamicFilters.byId[type]!.ids.filter(item => item !== id)
}

const removeTemporarySelectedDynamicFilterIds: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ type: string; ids: Array<number> }>
> = (draft, action) => {
  const { type, ids } = action.payload

  draft.temporarySelectedDynamicFilters.byId[type]!.ids =
    draft.temporarySelectedDynamicFilters.byId[type]!.ids.filter(item => !ids.includes(item))
}

const removeTemporarySelectedDynamicFilters: CaseReducer<DynamicFiltersState> = draft => {
  draft.temporarySelectedDynamicFilters = { byId: {}, ids: [] }
}

const removeTemporarySelectedDynamicFilter: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ type: string }>
> = (draft, action) => {
  const { type } = action.payload

  delete draft.temporarySelectedDynamicFilters.byId[type]
  draft.temporarySelectedDynamicFilters.ids = draft.temporarySelectedDynamicFilters.ids.filter(
    id => id !== type,
  )
}

const fetchDynamicFiltersRequest: CaseReducer<DynamicFiltersState> = draft => {
  draft.ui.uiState = UiState.Pending
}

const fetchDynamicFiltersSuccess: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{
    dynamicFilters?: Array<DynamicFilterModel>
    selectedDynamicFilters?: Array<SelectedDynamicFilterModel>
  }>
> = (draft, action) => {
  const { dynamicFilters, selectedDynamicFilters } = action.payload

  draft.selectedDynamicFilters = { byId: {}, ids: [] }
  draft.dynamicFilters = { byId: {}, ids: [] }
  draft.ui.errors = []
  draft.ui.uiState = UiState.Success

  selectedDynamicFilters?.forEach(selectedDynamicFilter => {
    draft.selectedDynamicFilters.byId[selectedDynamicFilter.type] = selectedDynamicFilter
    draft.selectedDynamicFilters.ids.push(selectedDynamicFilter.type)
  })
  dynamicFilters?.forEach(dynamicFilter => {
    draft.dynamicFilters.byId[dynamicFilter.type] = dynamicFilter
    draft.dynamicFilters.ids.push(dynamicFilter.type)
  })
}

const fetchDefaultDynamicFiltersRequest: CaseReducer<DynamicFiltersState> = draft => {
  draft.ui.uiState = UiState.Pending
}

const fetchDefaultDynamicFiltersSuccess: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{
    selectedDefaultFilters?: Array<SelectedDynamicFilterModel>
  }>
> = (draft, action) => {
  const { selectedDefaultFilters } = action.payload

  draft.selectedDefaultFilters = { byId: {}, ids: [] }

  selectedDefaultFilters?.forEach(selectedDynamicFilter => {
    draft.selectedDefaultFilters.byId[selectedDynamicFilter.type] = selectedDynamicFilter
    draft.selectedDefaultFilters.ids.push(selectedDynamicFilter.type)
  })
}

const fetchDefaultDynamicFiltersFailure: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ errors: Array<ErrorItem> }>
> = (draft, action) => {
  const { errors } = action.payload

  draft.ui.errors = errors
  draft.ui.uiState = UiState.Failure
}

const fetchTemporaryDynamicFiltersSuccess: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{
    dynamicFilters: Array<DynamicFilterModel>
    temporarySelectedDynamicFilters: Array<SelectedDynamicFilterModel>
  }>
> = (draft, action) => {
  const { dynamicFilters, temporarySelectedDynamicFilters } = action.payload

  draft.temporarySelectedDynamicFilters = { byId: {}, ids: [] }
  draft.dynamicFilters = { byId: {}, ids: [] }
  draft.ui.errors = []
  draft.ui.uiState = UiState.Success

  temporarySelectedDynamicFilters.forEach(selectedDynamicFilter => {
    draft.temporarySelectedDynamicFilters.byId[selectedDynamicFilter.type] = selectedDynamicFilter
    draft.temporarySelectedDynamicFilters.ids.push(selectedDynamicFilter.type)
  })
  dynamicFilters.forEach(dynamicFilter => {
    draft.dynamicFilters.byId[dynamicFilter.type] = dynamicFilter
    draft.dynamicFilters.ids.push(dynamicFilter.type)
  })
}

const fetchDynamicFiltersFailure: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ errors: Array<ErrorItem> }>
> = (draft, action) => {
  const { errors } = action.payload

  draft.ui.errors = errors
  draft.ui.uiState = UiState.Failure
}

const fetchDynamicFilterSearchSuccess: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{
    code: string
    options: Array<DynamicFilterOptionModel>
    isSelectionHighlighted?: boolean
  }>
> = (draft, action) => {
  const { code, options, isSelectionHighlighted } = action.payload

  draft.dynamicFilters.byId[code]!.options = options
  draft.dynamicFilters.byId[code]!.isSelectionHighlighted = isSelectionHighlighted
}

const setTemporaryCatalogIds: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ temporaryCatalogIds: Array<number> }>
> = (draft, action) => {
  const { temporaryCatalogIds } = action.payload

  draft.temporaryCatalogIds = temporaryCatalogIds
}

const removeTemporaryCatalogIds: CaseReducer<DynamicFiltersState> = draft => {
  draft.temporaryCatalogIds = []
}

const setTemporaryPriceRange: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ priceRange: PriceRange }>
> = (draft, action) => {
  const {
    priceRange: { priceTo, priceFrom },
  } = action.payload
  const newPriceRange = getNewPriceRange(priceFrom, priceTo)

  draft.temporaryPriceRange.priceFrom = newPriceRange.priceFrom
  draft.temporaryPriceRange.priceTo = newPriceRange.priceTo
}

const removeTemporaryPriceRange: CaseReducer<DynamicFiltersState> = draft => {
  draft.temporaryPriceRange = {
    priceFrom: null,
    priceTo: null,
  }
}

const setFilterSearchQuery: CaseReducer<
  DynamicFiltersState,
  PayloadAction<{ text: string | null; code: string | null }>
> = (draft, action) => {
  const { text, code } = action.payload

  draft.filterSearchQuery.text = text
  draft.filterSearchQuery.code = code
}

const dynamicFilterSlice = createSlice({
  name: stateName,
  initialState: initialDynamicFilterState,
  reducers: {
    fetchDynamicFiltersRequest,
    fetchDynamicFiltersSuccess,
    fetchDynamicFiltersFailure,
    fetchTemporaryDynamicFiltersRequest: fetchDynamicFiltersRequest,
    fetchTemporaryDynamicFiltersSuccess,
    fetchTemporaryDynamicFiltersFailure: fetchDynamicFiltersFailure,
    fetchDefaultDynamicFiltersRequest,
    fetchDefaultDynamicFiltersSuccess,
    fetchDefaultDynamicFiltersFailure,
    fetchDynamicFilterSearchSuccess,
    setTemporaryDynamicFiltersState: fetchTemporaryDynamicFiltersSuccess,
    setSelectedDynamicFilter,
    setSelectedDynamicFilters,
    removeSelectedDynamicFilterId,
    removeSelectedDynamicFilterIds,
    removeSelectedDynamicFilters,
    removeSelectedDynamicFilter,
    setTemporarySelectedDynamicFilter,
    setTemporarySelectedDynamicFilters,
    removeTemporarySelectedDynamicFilterId,
    removeTemporarySelectedDynamicFilterIds,
    removeTemporarySelectedDynamicFilters,
    removeTemporarySelectedDynamicFilter,
    setTemporaryCatalogIds,
    updateTemporaryCatalogIds: setTemporaryCatalogIds,
    removeTemporaryCatalogIds,
    setTemporaryPriceRange,
    removeTemporaryPriceRange,
    setFilterSearchQuery,
  },
  extraReducers: {
    [dataSlice.actions.fetchDataSuccess.type]: fetchDynamicFiltersSuccess,
  },
})

const changeDtos: CaseReducer<
  ApiData,
  PayloadAction<{
    dtos: ApiData
  }>
> = (draft, action) => {
  const { dtos } = action.payload

  Object.assign(draft, dtos)
}

const dtoSlice = createSlice({
  name: stateName,
  initialState: initialDtoData,
  reducers: {
    changeDtos,
  },
})

const fetchConfigDataRequest: CaseReducer<ConfigurationState> = draft => {
  draft.ui.uiState = UiState.Pending
}

const fetchConfigDataSuccess: CaseReducer<ConfigurationState, PayloadAction<ConfigurationData>> = (
  draft,
  action,
) => {
  const { currency, locale } = action.payload

  draft.ui.uiState = UiState.Success
  draft.config = {
    locale,
    currency,
  }
}

const fetchConfigDataFailure: CaseReducer<ConfigurationState> = draft => {
  draft.ui.uiState = UiState.Failure
}

const configurationSlice = createSlice({
  name: stateName,
  initialState: initialConfigurationState,
  reducers: {
    fetchConfigDataRequest,
    fetchConfigDataSuccess,
    fetchConfigDataFailure,
  },
})

const setCatalogTitle: CaseReducer<
  string | undefined | null,
  PayloadAction<string | null | undefined>
> = (_, action) => {
  return action.payload
}

const catalogTitleSlice = createSlice({
  name: stateName,
  initialState: null as undefined | string | null,
  reducers: {
    setCatalogTitle,
  },
})

const setCatalogBrand: CaseReducer<
  CatalogBrandState,
  PayloadAction<{
    brand?: BrandModel
  }>
> = (draft, action) => {
  const { brand } = action.payload

  draft.brand = brand
}

const removeCatalogBrand: CaseReducer<CatalogBrandState> = draft => {
  Object.assign(draft, initialCatalogBrandState)
}

const catalogBrandSlice = createSlice({
  name: stateName,
  initialState: initialCatalogBrandState,
  reducers: {
    setCatalogBrand,
    removeCatalogBrand,
  },
})

export const initialMetadataState: Metadata = {
  filtersChangeCount: 0,
}

const incrementFiltersChangeCount: CaseReducer<Metadata> = draft => {
  draft.filtersChangeCount += 1
}

const metadataSlice = createSlice({
  name: stateName,
  initialState: initialMetadataState,
  reducers: {
    incrementFiltersChangeCount,
  },
})

export const metadataReducer = metadataSlice.reducer
export const dtosReducer = dtoSlice.reducer
export const dataReducer = dataSlice.reducer
export const filtersReducer = filtersSlice.reducer
export const searchSessionReducer = searchSessionSlice.reducer
export const dynamicFiltersReducer = dynamicFilterSlice.reducer
export const catalogTitleReducer = catalogTitleSlice.reducer
export const configurationReducer = configurationSlice.reducer
export const catalogBrandReducer = catalogBrandSlice.reducer

export const actions = {
  ...metadataSlice.actions,
  ...filtersSlice.actions,
  ...dataSlice.actions,
  ...dtoSlice.actions,
  ...searchSessionSlice.actions,
  ...dynamicFilterSlice.actions,
  ...catalogTitleSlice.actions,
  ...configurationSlice.actions,
  ...catalogBrandSlice.actions,
}

const catalogFiltersReducer = combineReducers<State>({
  metadata: metadataReducer,
  dtos: dtosReducer,
  data: dataReducer,
  filters: filtersReducer,
  dynamicFilters: dynamicFiltersReducer,
  searchSession: searchSessionReducer,
  catalogTitle: catalogTitleReducer,
  configuration: configurationReducer,
  catalogBrand: catalogBrandReducer,
})

export const plug = { [stateName]: catalogFiltersReducer }
export default catalogFiltersReducer
