import { CreditCardEncryptedModel } from 'types/models'

export enum TokenizeStatus {
  Ok = 'ok',
  ProviderError = 'providerError',
  LibraryNotLoaded = 'libraryNoLoaded',
}

export type TokenizeResult = {
  status: TokenizeStatus
  encryptedCard?: string
  encryptedCardDetails?: CreditCardEncryptedModel
  error?: string
}
