import { CountryBoundsDto } from 'types/dtos'
import { CountryBoundsModel } from 'types/models'

export const transformCountryBoundsDto = ({
  radius,
  center,
  top_left,
  bottom_right,
  top_right,
  bottom_left,
}: CountryBoundsDto): CountryBoundsModel => ({
  radius,
  center,
  topLeft: top_left,
  bottomRight: bottom_right,
  topRight: top_right,
  bottomLeft: bottom_left,
})
