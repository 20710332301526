import { takeEvery, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'

import { transformReturnCurrencyConversionDto } from 'data/transformers/return-currency-conversion'
import { transformReturnShipmentOrderDto } from 'data/transformers/return-shipment-order'

import { actions } from './slice'

export function* prepareReturnLabelOrder({
  payload: { transactionId, pickupPointCode, packageTypeId, addressId },
}: ReturnType<typeof actions.prepareReturnLabelOrderRequest>) {
  const response = yield* call(api.prepareReturnLabelOrder, {
    transactionId,
    pickupPointCode,
    packageTypeId,
    addressId,
  })

  if ('errors' in response) {
    yield put(
      actions.prepareReturnLabelOrderFailure({ error: response?.errors?.[0]?.value || null }),
    )
  } else {
    const returnShipmentOrder = transformReturnShipmentOrderDto(response.return_label_order)
    const currencyConversion =
      response.return_label_order.currency_conversion &&
      transformReturnCurrencyConversionDto(response.return_label_order.currency_conversion)

    yield put(
      actions.prepareReturnLabelOrderSuccess({
        returnShipmentOrder,
        currencyConversion,
      }),
    )
  }
}

export function* getReturnLabelOrder({
  payload: { orderId },
}: ReturnType<typeof actions.getReturnLabelOrderRequest>) {
  const response = yield* call(api.getReturnLabelOrder, orderId)

  if ('errors' in response) {
    yield put(actions.getReturnLabelOrderFailure())

    return
  }

  const returnShipmentOrder = transformReturnShipmentOrderDto(response.return_label_order)

  yield put(
    actions.getReturnLabelOrderSuccess({
      returnShipmentOrder,
    }),
  )
}

export default function* saga() {
  yield takeEvery(actions.prepareReturnLabelOrderRequest, prepareReturnLabelOrder)
  yield takeEvery(actions.getReturnLabelOrderRequest, getReturnLabelOrder)
}
