import { flattenDeep } from 'lodash'

import { ROOT_CATALOG_ID } from 'constants/catalog'

import { CatalogDto, CatalogLandingDto, CatalogPhotoDto, ItemFacetCatalogDto } from 'types/dtos'
import { CatalogLandingModel, CatalogModel, CatalogWithoutParentIdModel } from 'types/models'

const transformCatalogLanding = (landing: CatalogLandingDto | null): CatalogLandingModel | null => {
  if (!landing) return null

  return {
    imageUrl: landing.image_url,
    itemsRemaining: landing.items_remaining,
  }
}

function isCatalogPhotoDto(value: Record<string, unknown>): value is CatalogPhotoDto {
  return 'url' in value
}

export const transformCatalogDto = (catalog: CatalogDto): CatalogWithoutParentIdModel => {
  const { id, title, code, url, url_en, photo, order, depth } = catalog

  return {
    id,
    title,
    code,
    url,
    photo: isCatalogPhotoDto(photo) ? photo : null,
    urlEn: url_en,
    order,
    depth: depth || 0,
    isDesigner: catalog.is_designer,
    customUrl: catalog.custom_url,
    isShippable: catalog.shippable,
    isColorAvailable: !!catalog.color_field_visibility,
    isLocationAvailable: catalog.location_field_visible,
    isSizesAvailable: !!catalog.size_field_visibility,
    isVideoGameRatingAvailable: !!catalog.video_game_rating_field_visibility,
    isBrandsAvailable: !!catalog.brand_field_visibility,
    isIsbnAvailable: !!catalog.isbn_field_visibility,
    isAuthorAvailable: !!catalog.author_field_visibility,
    isMeasurementsAvailable: !!catalog.measurements_field_visibility,
    isBookTitleAvailable: !!catalog.book_title_field_visibility,
    landing: transformCatalogLanding(catalog.landing),
    canBrowseSubcategories: catalog.allow_browsing_subcategories,
    restrictedToStatusId: catalog.restricted_to_status_id,
    unisexCatalogId: catalog.unisex_catalog_id,
    catalogIds: catalog.catalogs.map(item => item.id),
    sizeGroupId: catalog.size_group_id,
    sizeGroupIds: catalog.size_group_ids,
    itemCount: catalog.item_count,
    badge: catalog.badge,
    catalogs: catalog.catalogs.map(dto => transformCatalogDto(dto)),
  }
}

export const transformCatalogDtoList = (
  catalogList: Array<CatalogDto>,
): Array<CatalogWithoutParentIdModel> => {
  return catalogList.map(transformCatalogDto)
}

const catalogTree = (catalogs: Array<CatalogDto>, depth = 0): RecursiveArray<CatalogDto> =>
  catalogs.map(catalog => {
    const catalogWithDepth = {
      ...catalog,
      depth,
    }

    return [catalogWithDepth, ...catalogTree(catalog.catalogs, depth + 1)]
  })

const flattenCatalogDtos = (catalogs: Array<CatalogDto>): Array<CatalogDto> =>
  flattenDeep(catalogTree(catalogs))

const applyParentId = (
  catalog: CatalogWithoutParentIdModel,
  index: number,
  catalogs: Array<CatalogWithoutParentIdModel>,
): CatalogModel => {
  const parent = catalogs.find(it => it.catalogIds.includes(catalog.id))
  const parentId = parent ? parent.id : ROOT_CATALOG_ID

  return { ...catalog, parentId }
}

export const transformCatalogDtos = (catalogs: Array<CatalogDto>): Array<CatalogModel> =>
  flattenCatalogDtos(catalogs).map(transformCatalogDto).map(applyParentId)

export const getCatalogByCounts = (
  catalogs: Array<CatalogDto>,
  catalogCounts: Record<number, number>,
) =>
  catalogs.reduce(
    (accumulator: { catalogs: Array<CatalogDto>; count: number }, catalog) => {
      let filteredChildren: Array<CatalogDto> = []
      let childrenItemSum = 0

      if (catalog.catalogs.length) {
        const childrenCounts = getCatalogByCounts(catalog.catalogs, catalogCounts)

        filteredChildren = childrenCounts.catalogs
        childrenItemSum = childrenCounts.count
      }

      if (catalogCounts[catalog.id] || filteredChildren.length) {
        const itemCount = childrenItemSum + (catalogCounts[catalog.id] || 0)

        accumulator.catalogs.push({
          ...catalog,
          catalogs: filteredChildren,
          item_count: itemCount,
        })
        accumulator.count += itemCount
      }

      return accumulator
    },
    { catalogs: [], count: 0 },
  )

export const transformCatalogDtosWithCounts = (
  catalogs: Array<CatalogDto>,
  catalogCounts: Array<ItemFacetCatalogDto>,
): Array<CatalogModel> => {
  const counts = catalogCounts.reduce(
    (acc: Record<number, number>, { id, count }) => ({ ...acc, [id]: count }),
    {},
  )
  const catalogsWithCounts = getCatalogByCounts(catalogs, counts).catalogs

  return transformCatalogDtos(catalogsWithCounts)
}
