import { createSelector } from 'reselect'
import { isEqual } from 'lodash'

import { entitiesFromStructure } from 'libs/utils/redux'
import { BrandModel } from 'types/models'

import { BookDetailStatus, CatalogGroupFieldCodes } from './constants'
import { AllState, CatalogSelectType, DynamicAttribute } from './types'

const select = (state: AllState) => state.itemUpload
const selectConfiguration = (state: AllState) => select(state).configuration
const selectUi = (state: AllState) => select(state).ui
const selectFormData = (state: AllState) => select(state).formData
export const selectAttributes = (state: AllState) => select(state).attributes
export const selectPreviousAttributes = (state: AllState) => select(state).previousAttributes

export const getCatalogFieldsOrder = (state: AllState) =>
  selectConfiguration(state).catalogFieldsOrder
export const getMaxShippingPrice = (state: AllState) => selectConfiguration(state).maxShippingPrice
export const getCurrency = (state: AllState) => selectConfiguration(state).currency
export const getFirstTimeListingGuideline = (state: AllState) =>
  selectConfiguration(state).firstTimeListingGuideline

export const getMultipleSizeGroupsABTest = (state: AllState) =>
  selectConfiguration(state).abTests.multiple_size_groups_item_upload
export const getIsMultipleSizeGroupsABTestEnabled = createSelector(
  getMultipleSizeGroupsABTest,
  test => test?.variant === 'on',
)

export const getIsOfflineVerificationEligible = createSelector(
  select,
  ({ isOfflineVerificationEligible }) => isOfflineVerificationEligible,
)

export const getUiState = createSelector(selectUi, ({ uiState }) => uiState)

export const getAssignedPhotos = createSelector(
  selectAttributes,
  ({ assignedPhotos }) => assignedPhotos,
)

export const getIsOfflineVerificationModalOpen = createSelector(
  selectUi,
  ({ isOfflineVerificationModalOpen }) => isOfflineVerificationModalOpen,
)

export const getContainsWebPhoto = createSelector(
  selectUi,
  ({ containsWebPhoto }) => containsWebPhoto,
)

export const getIsFormDirty = createSelector(
  select,
  ({ attributes, previousAttributes }) => !isEqual(attributes, previousAttributes),
)

export const getIsBrandLuxury = (state: AllState) => selectFormData(state).brands.isLuxury

export const getBrandTitle = (state: AllState) => selectAttributes(state).brandTitle

export const getIsAuthenticityProofModalOpen = createSelector(
  selectUi,
  ({ isAuthenticityProofModalOpen }) => isAuthenticityProofModalOpen,
)

export const getSuggestedBrands = createSelector(selectFormData, ({ brands }) =>
  entitiesFromStructure(brands.suggested),
)

export const getSuggestedColors = createSelector(selectFormData, ({ colors }) =>
  entitiesFromStructure(colors.suggested),
)

export const getSuggestedSizes = createSelector(selectFormData, ({ sizes }) =>
  entitiesFromStructure(sizes.suggested),
)

export const getBrands = createSelector(selectFormData, ({ brands }) =>
  entitiesFromStructure(brands),
)

export const getManufacturer = (state: AllState) => select(state).attributes.manufacturer

export const getManufacturerLabel = (state: AllState) => select(state).attributes.manufacturerLabel

export const getIsManufacturerVisible = (state: AllState) =>
  !!select(state).formData.additionalAttributes.byName[CatalogGroupFieldCodes.Manufacturer]

export const getIsManufacturerLabelVisible = (state: AllState) =>
  !!select(state).formData.additionalAttributes.byName[CatalogGroupFieldCodes.ManufacturerLabel]

export const getBrandById = (state: AllState, id: number): BrandModel | undefined =>
  selectFormData(state).brands.byId[id]

export function getCatalogPath(state: AllState, catalogId: number) {
  const { catalogs } = select(state).formData

  const pathNames: Array<string> = []

  let parentId = catalogs.byId[catalogId]?.parentId

  if (!parentId) return null

  while (parentId) {
    const parent = catalogs.byId[parentId]!
    pathNames.unshift(parent.title)
    parentId = catalogs.byId[parentId]?.parentId
  }

  return pathNames.join(' > ')
}

export function getSuggestedCatalogs(state: AllState) {
  const { catalogs } = select(state).formData

  return catalogs.suggested
    .map(id => {
      const catalog = catalogs.byId[id]!

      return { catalog, path: getCatalogPath(state, id) }
    })
    .filter(suggestion => suggestion.catalog || suggestion.path)
}

export function getCatalogsByIds(state: AllState, ids: Array<number> | null) {
  const { catalogs } = select(state).formData
  const targetIds = ids?.length ? ids : catalogs.initialIds

  return targetIds.map(id => catalogs.byId[id]!)
}

export function getCatalog(state: AllState, id: number | null) {
  const { catalogs } = select(state).formData

  if (!id) return null

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  return catalogs?.byId[id]!
}

export function getChildCatalogsById(state: AllState, id: number | null) {
  const parentCatalog = getCatalog(state, id)

  return getCatalogsByIds(state, parentCatalog?.catalogIds ?? null)
}

export function getCurrentCatalog(state: AllState) {
  const { catalogId } = selectAttributes(state)

  if (!catalogId) return null

  return select(state).formData.catalogs.byId[catalogId]
}

export function getRestrictedToStatusId(state: AllState) {
  return getCurrentCatalog(state)?.restrictedToStatusId
}

export function getSize(state: AllState, id: number) {
  const { sizes } = select(state).formData

  return sizes.byId[id]
}

export function getCurrentSize(state: AllState) {
  const { sizeId } = selectAttributes(state)

  if (!sizeId) return null

  return getSize(state, sizeId)
}

export const getIsShippingOptionsModalOpen = createSelector(
  selectUi,
  ({ shippingOptions }) => shippingOptions.isModalOpen,
)

export const getIsLuxuryItemModalOpen = (state: AllState) => selectUi(state).isLuxuryItemModalOpen

export const showMissingPostalCode = (state: AllState) => selectUi(state).showMissingPostalCode

export const getShippingOptionsUiState = createSelector(
  selectUi,
  ({ shippingOptions }) => shippingOptions.uiState,
)

export const getShippingOptions = createSelector(
  selectFormData,
  ({ shippingOptions }) => shippingOptions,
)

export const getPhotoTips = createSelector(selectFormData, ({ photoTips }) =>
  entitiesFromStructure(photoTips),
)

export const getSizeGroups = createSelector(selectFormData, ({ sizeGroups }) =>
  entitiesFromStructure(sizeGroups),
)

export function getCurrentSizeGroup(state: AllState) {
  const catalog = getCurrentCatalog(state)
  const { sizeGroups } = select(state).formData

  if (catalog?.sizeGroupId === undefined || catalog?.sizeGroupId === null || !sizeGroups) {
    return null
  }

  if (catalog.catalogIds.length !== 0) return null

  return sizeGroups.byId[catalog.sizeGroupId]
}

export function getCurrentSizes(state: AllState) {
  const sizeGroup = getCurrentSizeGroup(state)

  if (!sizeGroup) return []

  return sizeGroup.sizes
}

export const getIsPackageSizesVisible = createSelector(
  selectAttributes,
  ({ catalogId }) => !!catalogId,
)

export const getIsFormDisabled = createSelector(selectUi, ({ isFormDisabled }) => isFormDisabled)

export const getPriceSuggestions = createSelector(
  selectFormData,
  ({ priceSuggestions }) => priceSuggestions,
)

export const getPriceSuggestionsMin = createSelector(getPriceSuggestions, ({ min }) => min)

export const getPriceSuggestionsMax = createSelector(getPriceSuggestions, ({ max }) => max)

export const getPriceSuggestionsCurrency = createSelector(
  getPriceSuggestions,
  ({ currency }) => currency,
)

export const showSimilarItems = createSelector(selectUi, ({ price }) => price.hasSimilarItems)

export const getShowPriceSuggestions = (state: AllState) => {
  const min = getPriceSuggestionsMin(state)
  const max = getPriceSuggestionsMax(state)
  const { price } = selectAttributes(state)

  return max && min && price && price > max
}

export const getVisibilityFactory = (
  visibilityProp: KeysOfType<CatalogSelectType, boolean>,
  defaultVisibility = false,
) =>
  createSelector(getCurrentCatalog, catalog =>
    catalog ? catalog[visibilityProp] : defaultVisibility,
  )

export const getIsBrandVisible = getVisibilityFactory('isBrandSelectShown', true)
export const getIsSizeVisible = getVisibilityFactory('isSizeSelectShown')
export const getIsColorVisible = getVisibilityFactory('isColorSelectShown')
export const getIsVideoGameRatingVisible = getVisibilityFactory('isVideoGameRatingSelectShown')
export const getIsIsbnVisible = getVisibilityFactory('isIsbnInputShown')
export const getIsAuthorVisible = getVisibilityFactory('isAuthorInputShown')
export const getIsBookTitleVisible = getVisibilityFactory('isBookTitleInputShown')
export const getIsMeasurementsVisible = getVisibilityFactory('isMeasurementsSelectShown')

export const getIsbnUiState = createSelector(selectUi, ({ isbn }) => isbn.uiState)
export const getIsbnAuthor = createSelector(selectFormData, ({ bookDetails }) =>
  bookDetails ? bookDetails.author : null,
)
export const getIsbnBookTitle = createSelector(selectFormData, ({ bookDetails }) =>
  bookDetails ? bookDetails.title : null,
)

export function getBookDetailStatus(state: AllState) {
  const { bookDetails } = selectFormData(state)
  const isIsbnVisible = getIsIsbnVisible(state)

  if (!isIsbnVisible || !bookDetails) return null

  if (bookDetails.author || bookDetails.title) return BookDetailStatus.Found

  return BookDetailStatus.NotFound
}

export const getCanBumpItem = createSelector(selectUi, ({ canBumpItem }) => canBumpItem)

export const getDynamicAttributesSelector = (fieldName: string) => (state: AllState) =>
  selectFormData(state).dynamicAttributes.byName[fieldName] || undefined

export const getDynamicAttributesUiState = createSelector(
  selectUi,
  ({ dynamicAttributes }) => dynamicAttributes.uiState,
)

export const getSelectedDynamicAttributeValue = (state: AllState, fieldName: string) =>
  selectAttributes(state).catalog.attributes.byName[fieldName] || []

export const getSelectedDynamicAttributes = (state: AllState) => {
  const {
    catalog: { attributes },
  } = selectAttributes(state)
  const { dynamicAttributes } = selectFormData(state)

  return attributes.names
    .map(name => {
      if (!dynamicAttributes.byName[name]) return null

      return {
        field: name,
        value: attributes.byName[name],
      }
    })
    .filter((attribute): attribute is DynamicAttribute => !!attribute)
}

export const getIsPackageSizeSelectedManually = (state: AllState) => {
  const { isPackageSizeSelectedManually } = select(state)

  return isPackageSizeSelectedManually
}

export const getIsPromoted = createSelector(selectAttributes, ({ isPromoted }) => isPromoted)

export const getAlertType = createSelector(selectAttributes, ({ alertType }) => alertType)
export const getPreviousAlertType = createSelector(
  selectPreviousAttributes,
  ({ alertType }) => alertType,
)

export const getFeedbackId = createSelector(selectAttributes, ({ feedbackId }) => feedbackId)

export const getMeasurementWidth = createSelector(
  selectAttributes,
  ({ measurementWidth }) => measurementWidth,
)
export const getMeasurementLength = createSelector(
  selectAttributes,
  ({ measurementLength }) => measurementLength,
)

export const getIsBumpChecked = createSelector(
  selectAttributes,
  ({ isBumpChecked }) => isBumpChecked,
)

export const getCurrentStatusId = (state: AllState) => selectAttributes(state).statusId
