import { compact } from 'lodash'

import { AddressModel } from 'types/models'

export const getFormattedAddress = (address: AddressModel | null | undefined) => {
  if (!address) return ''

  return compact([
    address?.line1,
    address?.line2,
    address?.city,
    address?.state,
    address?.postalCode,
  ]).join(' ')
}
