import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { PayInMethodModel } from 'types/models'

import { UiState } from 'constants/ui'
import { CreditCardAddReturnResult } from 'constants/credit-card'

import { PaymentState, PayInMethodsState, PaymentFormState } from './types'
import { stateName } from '../constants'

export const initialFormState: PaymentFormState = {
  errors: {
    isCardExpired: false,
  },
}

export const initialPayInMethodsState: PayInMethodsState = {
  ui: UiState.Idle,
  payInMethods: [],
}

export const initialState: PaymentState = {
  formState: initialFormState,
  isCardSingleUseOverriden: false,
  payInMethodsState: initialPayInMethodsState,
}

const setIsCardExpired: CaseReducer<PaymentState, PayloadAction<{ isExpired: boolean }>> = (
  draft,
  action,
) => {
  draft.formState.errors.isCardExpired = action.payload.isExpired
}

const setIsCardSingleUseOverriden: CaseReducer<
  PaymentState,
  PayloadAction<{ overrideSingleUse: boolean }>
> = (draft, action) => {
  draft.isCardSingleUseOverriden = action.payload.overrideSingleUse
}

const handleCardAddAuthResult: CaseReducer<
  PaymentState,
  PayloadAction<{
    cardId?: string
    transactionId: number
    cardAddResult: CreditCardAddReturnResult
  }>
> = (draft, action) => {
  const { cardAddResult } = action.payload

  draft.cardAddAuth = {
    cardAddResult,
  }
}

const fetchPayInMethodsRequest: CaseReducer<
  PaymentState,
  PayloadAction<{ transactionId: number }>
> = draft => {
  draft.payInMethodsState.ui = UiState.Pending
}

const fetchPayInMethodsFailure: CaseReducer<PaymentState> = draft => {
  draft.payInMethodsState.ui = UiState.Failure
}

const fetchPayInMethodsSuccess: CaseReducer<
  PaymentState,
  PayloadAction<{ payInMethods: Array<PayInMethodModel> }>
> = (draft, action) => {
  draft.payInMethodsState.ui = UiState.Success
  draft.payInMethodsState.payInMethods = action.payload.payInMethods
}

const paymentSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setIsCardExpired,
    handleCardAddAuthResult,
    fetchPayInMethodsRequest,
    fetchPayInMethodsFailure,
    fetchPayInMethodsSuccess,
    setIsCardSingleUseOverriden,
  },
})

export const { actions } = paymentSlice
export const plug = { [stateName]: paymentSlice.reducer }
export default paymentSlice.reducer
