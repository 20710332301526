import { PortalMergeAnnouncementBannerDto } from 'types/dtos'
import { PortalMergeBannerModel } from 'types/models'

export const transformPortalMergeAnnouncement = ({
  animation_url,
  image_url,
  heading,
  text,
  text_link_title,
  text_link_url,
  cta_title,
  cta_url,
  close_title,
  close_url,
}: PortalMergeAnnouncementBannerDto): PortalMergeBannerModel => ({
  animationUrl: animation_url,
  imageUrl: image_url,
  heading,
  text,
  textLinkTitle: text_link_title,
  textLinkUrl: text_link_url,
  primaryActionTitle: cta_title,
  primaryActionUrl: cta_url,
  closeActionTitle: close_title,
  closeActionUrl: close_url ?? undefined,
})
