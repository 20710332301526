import { BrandDto } from 'types/dtos'
import { BrandModel } from 'types/models'

export const transformBrandDto = (brand: BrandDto): BrandModel => {
  const { id, title, slug, path, url } = brand

  return {
    id,
    title,
    slug,
    path,
    url,
    itemCount: brand.item_count,
    favouriteCount: brand.favourite_count,
    requiresAuthenticityCheck: brand.requires_authenticity_check,
    isLuxury: brand.is_luxury,
    isVisibleInListings: brand.is_visible_in_listings,
    isFavourite: brand.is_favourite,
    isHated: brand.is_hated,
    isHvf: brand.is_hvf,
  }
}

export const transformBrandDtos = (brands: Array<BrandDto>): Array<BrandModel> =>
  brands.map(transformBrandDto)
