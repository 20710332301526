import { takeEvery, put } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import * as api from 'data/api'
import { transformBusinessAccountAddressDto } from 'data/transformers/business-account'
import { getBusinessAccountId } from 'state/session/selectors'

import { BusinessAddressesModel } from './types'
import { actions } from './slice'
import * as statelessActions from './actions'

export function* fetchBusinessAddresses() {
  const businessAccountId = yield* select(getBusinessAccountId)
  const response = yield* call(api.getBusinessAccountAddresses, {
    businessAccountId: businessAccountId as number,
  })

  if ('errors' in response) {
    return
  }

  const state: BusinessAddressesModel = {
    businessAddress: null,
    returnAddress: null,
    isUsingTheSameAddress: true,
  }

  const {
    addresses: { business_address, return_address },
  } = response

  if (business_address) {
    state.businessAddress = transformBusinessAccountAddressDto(business_address)
  }

  if (return_address) {
    state.returnAddress = transformBusinessAccountAddressDto(return_address)
    state.isUsingTheSameAddress = false
  }

  yield put(actions.fetchBusinessAddressesComplete(state))
}

export default function* saga() {
  yield takeEvery(statelessActions.fetchBusinessAddresses.type, fetchBusinessAddresses)
}
