'use client'

import { useEffect, useMemo } from 'react'
import type { AppProps } from 'next/app'
import { IntlProvider } from 'react-intl'
import { datadogRum } from '@datadog/browser-rum'
import { AuthzProvider } from '@vinted/authz-web'

import { BreakpointProvider } from 'components/Breakpoint'
import ErrorBoundary from 'components/ErrorBoundary'
import BrazeProvider from 'libs/common/braze/containers/BrazeProvider'
import NextRequestProvider from 'containers/RequestProvider/NextRequestProvider'
import TrackingProvider from 'containers/TrackingProvider'
import CookieManagerProvider from 'libs/common/cookie-manager/cookie-manager-provider'
import SystemConfigurationProvider from 'contexts/SystemConfigurationProvider'
import { initialiseMonitoring } from 'startup/monitoring'

import clientSideMetrics from 'libs/common/client-side-metrics'
import appHealth from 'libs/common/app-health/app-health'
import { createCookieManager } from 'libs/common/cookie-manager/helpers'
import AuthModalProvider from 'libs/common/auth-modal/AuthModalProvider'
import DataDomeProvider from 'libs/common/datadome/containers/DataDomeProvider'
import AbTestsProvider from 'contexts/AbTestsProvider'
import FeatureSwitchesProvider from 'contexts/FeatureSwitchesProvider'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import SessionProvider from 'contexts/SessionProvider'
import EnvsProvider from 'contexts/EnvsProvider'
import { VINTED_LOCALE_TO_ISO_MAP } from 'constants/language'
import UserStatsProvider from 'contexts/UserStatsProvider'

import CustomErrorComponent from './_error'
import BottomScripts from '../components/BottomScripts'
import CanaryToken from '../components/CanaryToken'
import ConsentBannerScript from '../components/ConsentBannerScript'
import GoogleTagManager from '../components/GoogleTagManager'
import AppHead from '../components/Head/AppHead'
import TrackRerender from '../components/TrackRerender'
import TrackScreen from '../components/TrackScreen'
import TrackWebVitals from '../components/TrackWebVitals'
import DataDomeScript from '../components/DataDomeScript'
import { AppProps as CustomAppProps } from '../libs/server-utils/ssr'

import { wrapper } from '../state/store'

import 'startup/intlPolyfills'
import './app.scss'

initialiseMonitoring()

type Props = {
  _app: CustomAppProps
}

const ConditionalAuthzProvider = ({ children, permissions }) => {
  const isAuthzEnabled = useFeatureSwitch('next_authz')

  if (isAuthzEnabled) {
    return <AuthzProvider permissions={permissions}>{children}</AuthzProvider>
  }

  return children
}

const App = ({ Component, pageProps, router }: AppProps<Partial<Props>>) => {
  const { _app: appProps, ...componentProps } = pageProps

  const cookieManager = useMemo(() => createCookieManager(appProps?.cookies), [appProps?.cookies])

  appHealth.disableForTestUsers(appProps?.sessionData.user?.email)

  useEffect(() => {
    datadogRum.setGlobalContextProperty('page', router.pathname)
    clientSideMetrics.counter('page_load', { page: router.pathname }).increment()
  }, [router.pathname])

  function renderCanaryToken() {
    if (process.env.NODE_ENV !== 'production') return null

    return (
      <ErrorBoundary>
        <CanaryToken />
      </ErrorBoundary>
    )
  }

  function renderPageWithoutContext() {
    return (
      <div className="next-page">
        <AppHead />
        <Component {...componentProps} />
        {renderCanaryToken()}
      </div>
    )
  }

  if (['/500', '/404', '/_error'].includes(router.pathname)) {
    return renderPageWithoutContext()
  }

  if (!appProps) {
    if (Component === CustomErrorComponent) return renderPageWithoutContext()

    throw new Error('Page is rendered without layout wrapper')
  }

  const localeFromProps = VINTED_LOCALE_TO_ISO_MAP[appProps.locale] || appProps.locale

  // needs to be destructured because LinkifiedMessage component tries to add a property, which causes a type error:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cant_define_property_object_not_extensible
  const translationMessagesFromProps = { ...appProps.translationMessages }

  return (
    <>
      <AppHead url={appProps.url} locale={appProps.locale} />
      <FeatureSwitchesProvider featureSwitches={appProps.featureSwitches}>
        <EnvsProvider envs={appProps.envs}>
          <SystemConfigurationProvider configuration={appProps.systemConfiguration}>
            <SessionProvider initialSessionData={appProps.sessionData}>
              <ConditionalAuthzProvider permissions={appProps.permissions}>
                <AbTestsProvider initialAbTests={appProps.abTests}>
                  <IntlProvider locale={localeFromProps} messages={translationMessagesFromProps}>
                    <UserStatsProvider>
                      <ErrorBoundary
                        FallbackComponent={ErrorBoundary.AppError}
                        pathname={router.pathname}
                        shouldIncrementCounter
                      >
                        <CookieManagerProvider cookieManager={cookieManager}>
                          <TrackingProvider>
                            <NextRequestProvider
                              locationUrl={appProps.url}
                              pageId={appProps.pageId}
                              userAgent={appProps.userAgent}
                            >
                              <BrazeProvider>
                                <TrackScreen>
                                  <BreakpointProvider
                                    ssrConfigs={{
                                      device: appProps.device,
                                      isBot: appProps.isBot,
                                      viewportSize: appProps.viewportSize,
                                      isWebview: appProps.sessionData.isWebview,
                                    }}
                                  >
                                    <AuthModalProvider>
                                      <DataDomeProvider>
                                        <TrackWebVitals />
                                        <TrackRerender
                                          viewportSize={appProps.viewportSize}
                                          device={appProps.device}
                                          isBot={appProps.isBot}
                                          isWebview={appProps.sessionData.isWebview}
                                        />
                                        <div className="next-page">
                                          <Component {...componentProps} />
                                          {renderCanaryToken()}
                                        </div>
                                        <GoogleTagManager />
                                        <ConsentBannerScript />
                                        <BottomScripts />
                                        <DataDomeScript />
                                      </DataDomeProvider>
                                    </AuthModalProvider>
                                  </BreakpointProvider>
                                </TrackScreen>
                              </BrazeProvider>
                            </NextRequestProvider>
                          </TrackingProvider>
                        </CookieManagerProvider>
                      </ErrorBoundary>
                    </UserStatsProvider>
                  </IntlProvider>
                </AbTestsProvider>
              </ConditionalAuthzProvider>
            </SessionProvider>
          </SystemConfigurationProvider>
        </EnvsProvider>
      </FeatureSwitchesProvider>
    </>
  )
}

const AppWithStore = wrapper.withRedux(App)

export default AppWithStore
