import {
  FirstTimeListerGuidelineDto,
  FirstTimeListerGuidelineInformationDto,
  FirstTimeListerGuidelineSectionDto,
} from 'types/dtos/first-time-lister-guideline'
import {
  FirstTimeListerGuidelineInformationModel,
  FirstTimeListerGuidelineModel,
  FirstTimeListerGuidelineSectionModel,
} from 'types/models/first-time-lister-guideline'

const transformFirstTimeListerGuidlineSectionDto = (
  dto: FirstTimeListerGuidelineSectionDto,
): FirstTimeListerGuidelineSectionModel => ({
  title: dto.title,
  body: dto.body,
  imageUrl: dto.image_url,
})

const transformFirstTimeListerGuidlineInformationDto = (
  dto: FirstTimeListerGuidelineInformationDto,
): FirstTimeListerGuidelineInformationModel => ({
  title: dto.title,
  sections: dto.sections.map(transformFirstTimeListerGuidlineSectionDto),
  actions: dto.actions,
})

export const transformFirstTimeListerGuidlineDto = (
  dto: FirstTimeListerGuidelineDto,
): FirstTimeListerGuidelineModel => ({
  name: dto.name,
  title: dto.title,
  actionText: dto.action_text,
  additionalInformation: transformFirstTimeListerGuidlineInformationDto(dto.additional_information),
})
