import { ColorDto } from 'types/dtos'
import { ColorModel } from 'types/models'

export const transformColorDto = (color: ColorDto): ColorModel => {
  const { id, title, code, hex, order } = color

  return {
    id,
    title,
    code,
    hex,
    order,
  }
}

export const transformColorDtos = (colors: Array<ColorDto>): Array<ColorModel> =>
  colors.map(transformColorDto)
