import { Badge } from '@vinted/web-ui'

import { ItemPhotoDto, ItemAlertDto } from 'types/dtos'
import { ItemPhotoModel, ProductItemModel } from 'types/models'
import { GetUserItemsResp } from 'types/api'
import { ItemThumbnailSize } from 'constants/images'
import { ItemAlertStatus, ItemClosingAction } from 'constants/item'
import { transformAlertToItemStatus } from 'data/transformers/item-status'
import { ItemStatus } from 'types/item-status'

type ItemStatusProps = {
  isDraft?: boolean
  isReserved?: boolean
  isHidden?: boolean
  isClosed?: boolean
  itemClosingAction?: ItemClosingAction | null
  statsVisible?: boolean
  itemAlertType?: ItemAlertStatus
  itemAlert?: ItemAlertDto
  isProcessing?: boolean
  promoted?: boolean
}

const getItemThumbnail = (photos: Array<ItemPhotoDto>, size = ItemThumbnailSize.Medium) => {
  if (!photos) return null

  const photo = photos.find(({ is_main }) => is_main)

  if (!photo) return null

  const thumbnails = photo.thumbnails || []
  const thumbnail = thumbnails.find(thumb => thumb.type === size)

  if (!thumbnail) return null

  return thumbnail.url
}

export const getItemPhotoThumbnail = (
  photos: Array<ItemPhotoModel> | undefined,
  size = ItemThumbnailSize.Medium,
) => {
  return (
    photos?.find(photo => photo.isMain)?.thumbnails?.find(thumb => thumb.type === size)?.url || null
  )
}

const getItemPhotosThumbnails = (photos: Array<ItemPhotoDto>, size = ItemThumbnailSize.Medium) => {
  if (photos.length === 0) return undefined

  return photos
    .map(photo => photo.thumbnails.find(thumb => thumb.type === size)?.url)
    .filter((photoUrl): photoUrl is string => !!photoUrl)
}

const getItemStatus = (
  item: ItemStatusProps,
  showSensitive = false,
): ItemStatus | null | undefined => {
  const {
    isDraft,
    isReserved,
    isHidden,
    isClosed,
    itemClosingAction,
    statsVisible,
    itemAlertType,
    itemAlert,
    isProcessing,
    promoted,
  } = item

  if (isProcessing) return 'processing'
  if (
    itemAlert?.item_alert_type &&
    showSensitive &&
    itemAlert.item_alert_type !== ItemAlertStatus.EligibleForAuthenticity &&
    itemAlert.item_alert_type !== ItemAlertStatus.VerifiedOnlineAuthenticity
  ) {
    return transformAlertToItemStatus(itemAlert.item_alert_type)
  }
  if (itemAlertType && showSensitive) return transformAlertToItemStatus(itemAlertType)
  if (isDraft) return 'draft'
  if (isClosed) {
    return itemClosingAction
  }
  if (isReserved) return 'reserved'
  if (isHidden) return 'hidden'
  if (!statsVisible || !showSensitive) return null
  if (promoted) return 'bumped'

  return null
}

const getProductItemStatus = (item: ProductItemModel, showSensitive = false): ItemStatus | null => {
  const {
    isDraft,
    isReserved,
    isHidden,
    isClosed,
    canPushUp,
    areStatsVisible,
    itemAlertType,
    itemAlert,
    itemClosingAction,
    isProcessing,
    isPromoted,
  } = item

  if (isProcessing) return 'processing'
  if (
    itemAlert?.itemAlertType &&
    showSensitive &&
    itemAlert.itemAlertType !== ItemAlertStatus.EligibleForAuthenticity &&
    itemAlert.itemAlertType !== ItemAlertStatus.VerifiedOnlineAuthenticity
  ) {
    return transformAlertToItemStatus(itemAlert.itemAlertType)
  }
  if (itemAlertType && showSensitive) return transformAlertToItemStatus(itemAlertType)
  if (isDraft) return 'draft'
  if (isClosed && itemClosingAction) {
    return itemClosingAction
  }
  if (isReserved) return 'reserved'
  if (isHidden) return 'hidden'
  if (!areStatsVisible) return null
  if (!showSensitive) return null
  if (canPushUp) return null
  if (isPromoted) return 'bumped'

  return null
}

const getItemStatusMessage = (status: ItemStatus | null | undefined) => {
  if (!status) return null

  let theme: NonNullable<ComponentProps<typeof Badge>['theme']> | null = null
  let content = `item.status.${status}`

  switch (status) {
    case 'processing':
      content = 'item.status.processing'
      theme = 'amplified'
      break

    case 'reserved':
      theme = 'amplified'
      break

    case 'bumped':
      theme = 'primary'
      break

    case 'draft':
    case 'hidden':
      theme = 'muted'
      break

    case 'sold':
      theme = 'success'
      break

    case 'missing_subcategory':
    case 'change_description':
    case 'hidden_from_catalog':
    case 'package_size':
      theme = 'warning'
      break

    case 'delayed':
      content = 'item.status.delayed'
      theme = 'amplified'
      break

    case ItemAlertStatus.UnderReview:
      content = 'item.status.under_review'
      theme = 'amplified'
      break

    case ItemAlertStatus.ReplicaProof:
      content = 'item.status.hidden'
      theme = 'warning'
      break

    default:
      theme = null
  }

  if (theme === null) return null

  return {
    content,
    theme,
  }
}

const mergeItemsWithDrafts = (response: GetUserItemsResp) => [...response.drafts, ...response.items]

export {
  getItemThumbnail,
  getItemPhotosThumbnails,
  getItemStatus,
  getItemStatusMessage,
  getProductItemStatus,
  mergeItemsWithDrafts,
}
