import { DEFAULT_SSR_MOBILES_BREAKPOINTS, DEFAULT_SSR_BREAKPOINTS } from 'constants/breakpoint'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'
import { CookieHandler } from 'libs/common/cookie-manager/types'
import { getBreakpointsFromDevice, getBreakpointsFromWidth } from 'libs/utils/breakpoints'
import { serverSide } from 'libs/utils/environment'
import { getBrowserHeight, getBrowserWidth } from 'libs/utils/window'
import { BreakpointList, Device } from 'types/components'

export type SsrBreakpointsConfigs = {
  viewportSize: number | undefined
  device: Device | undefined
  isBot: boolean
  isWebview: boolean
}

const defaultBreakpointSizeOrder: Array<'wide' | 'desktops' | 'tablets' | 'phones'> = [
  'wide',
  'desktops',
  'tablets',
  'phones',
]

export const getServerSideBreakpoints = ({
  viewportSize,
  device,
  isWebview,
}: SsrBreakpointsConfigs) => {
  if (viewportSize) return getBreakpointsFromWidth(viewportSize)
  if (device) return getBreakpointsFromDevice(device)
  if (isWebview) return DEFAULT_SSR_MOBILES_BREAKPOINTS

  return DEFAULT_SSR_BREAKPOINTS
}

export const getFirstListedBreakpoint = <
  T extends BreakpointList = typeof defaultBreakpointSizeOrder,
>(
  activeBreakpoints: BreakpointList,
  targetOrder: T = defaultBreakpointSizeOrder as T,
): T[0] | null =>
  targetOrder.filter(breakpoint => activeBreakpoints.includes(breakpoint))[0] || null

export const setViewportCookie = (cookies: CookieHandler) => {
  if (serverSide) return

  cookies.set(cookiesDataByName.viewport_size, getBrowserWidth().toString())
}

export const getClientSideBreakpoints = () => getBreakpointsFromWidth(getBrowserWidth())

export const shouldUseSsrBreakpoints = (isBot: boolean) => {
  if (serverSide) return true
  if (isBot) return true

  return !getBrowserWidth() || !getBrowserHeight()
}

export const getInitialBreakpoints = (ssrConfigs?: SsrBreakpointsConfigs) => {
  if (ssrConfigs && shouldUseSsrBreakpoints(ssrConfigs.isBot)) {
    return getServerSideBreakpoints(ssrConfigs)
  }

  return getClientSideBreakpoints()
}
