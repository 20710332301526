import { createSelector } from 'reselect'
import { compact } from 'lodash'

import { transformAddress } from 'data/transformers/address'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getUser = (state: AllState) => localState(state).user

export const getUserId = (state: AllState): number | null => {
  const user = getUser(state)

  if (!user) return null

  return user.id
}

export const getUserExternalId = (state: AllState) => getUser(state)?.external_id || null

export const getUserEmail = (state: AllState): string | null => {
  const user = getUser(state)

  if (!user) return null

  return user.email
}

export const getUserCountryCode = createSelector(getUser, user => user?.country_code)

export const getUserFavouriteTopicCount = createSelector(
  getUser,
  user => user?.favourite_topic_count,
)

export const getUserCountryIsoCode = createSelector(getUser, user => user?.country_iso_code)

export const getUserRealName = createSelector(getUser, user => user?.real_name)

export const getUserDefaultAddress = createSelector(
  getUser,
  user => user?.default_address && transformAddress(user.default_address),
)

export const getFormattedAddress = createSelector(getUserDefaultAddress, address =>
  compact([
    address?.line1,
    address?.line2,
    address?.city,
    address?.state,
    address?.postalCode,
  ]).join(' '),
)

export const getUserRegistrationDate = createSelector(
  getUser,
  user => user?.created_at && new Date(user.created_at),
)

export const getCityTitle = (state: AllState): string | null => {
  const user = getUser(state)

  if (!user) return null

  return user.city
}

export const getCityId = (state: AllState): number | null => {
  const user = getUser(state)

  if (!user) return null

  return user.city_id
}

export const getCountryId = (state: AllState): number | null => {
  const user = getUser(state)

  if (!user) return null

  return user.country_id
}

export const getCountryTitle = createSelector(getUser, user => user?.country_title)

export const getIsLocationPublic = (state: AllState): boolean => {
  const user = getUser(state)

  return !!user?.is_location_public
}

export const getIsUserGod = (state: AllState): boolean => {
  const user = getUser(state)

  return !!user?.is_god
}

export const getUserPhoto = (state: AllState) => localState(state).user?.photo || null
export const getAnonId = (state: AllState) => localState(state).anon_id
export const getTrackingPlatform = (state: AllState) => localState(state).tracking_platform
export const getTrackerDebugPin = (state: AllState) => localState(state).tracker_debug_pin
export const getLanguageCode = (state: AllState) => localState(state).languageCode
export const getIsUserPhoneValid = (state: AllState) => getUser(state)?.verification.phone.valid
export const getIsUserEmailValid = (state: AllState) => getUser(state)?.verification.email.valid
export const getIsUserRestrictedByTerms = (state: AllState) => getUser(state)?.restricted_by_terms
export const getUserItemCount = (state: AllState) => getUser(state)?.item_count || 0
export const getUserBundleDiscount = (state: AllState) => getUser(state)?.bundle_discount
export const getHasConfirmedPaymentsAccount = (state: AllState) =>
  getUser(state)?.has_confirmed_payments_account
export const getIsFundraiserActive = (state: AllState) => !!getUser(state)?.fundraiser?.active
export const getFundraiserPercentage = (state: AllState) => getUser(state)?.fundraiser?.percentage

export const getIsCatalogModerator = (state: AllState) => getUser(state)?.is_catalog_moderator
export const getIsCatalogRoleMarketingPhotos = (state: AllState) =>
  getUser(state)?.is_catalog_role_marketing_photos

export const getIsAuthenticated = (state: AllState) => !!getUser(state)
export const getBusinessAccount = (state: AllState) => getUser(state)?.business_account
export const getBusinessAccountId = (state: AllState) => getBusinessAccount(state)?.id
export const getIsBusinessAccount = (state: AllState) => !!getUser(state)?.business

// TODO: drop these 👇 selectors after we fully migrated to the next.js and use getBrazeConfigs
export const getSessionBrazeConfig = (state: AllState) => localState(state).braze

// TODO: drop these 👇 selectors after we fully migrated to the next.js and use next-env directly
export const getSessionFacebookAppId = (state: AllState) => localState(state).facebookAppId
export const getSessionAppleClientId = (state: AllState) => localState(state).appleClientId
export const getSessionGoogleClientId = (state: AllState) => localState(state).googleClientId
export const getSessionGoogleMapsApiKey = (state: AllState) => localState(state).googleMapsApiKey

export const getIsSmartAdServerAvailable = (state: AllState) =>
  localState(state).isSmartAdServerAvailable

export const getCountryCode = createSelector(localState, ({ countryCode }) => countryCode)

export const getExposee = createSelector(
  [getAnonId, getUserId, getCountryCode],
  (anonId, userId, countryCode) => ({
    anonId,
    userId,
    countryCode,
  }),
)

export const getIsAccountBanned = createSelector(getUser, user => !!user?.is_account_banned)

export const getIsWebView = (state: AllState) => localState(state).isWebView
export const getIsContentOnlyView = (state: AllState) => localState(state).isContentOnlyView
