import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  Store,
  Middleware,
  StoreEnhancer,
  Reducer,
  Action,
  Dispatch,
} from '@reduxjs/toolkit'
import { datadogRum } from '@datadog/browser-rum'
import createSagaMiddleware from 'redux-saga'
import type { SagaMiddlewareOptions } from 'redux-saga'
import { all } from 'redux-saga/effects'

import { serverSide } from 'libs/utils/environment'
import { logError } from 'libs/utils/window'

import { RailsReduxStoreProps } from 'types/rails-redux-store'

import { staticReducers } from './reducers'
import { getStaticSagas } from './sagas'
import { Reducers, Sagas } from './types'

let globalStore: Store | undefined

export function createReducers(reducers?: Reducers) {
  return combineReducers({
    ...staticReducers,
    ...reducers,
  })
}

const crashReporter = () => (next: Dispatch<Action>) => (action: Action) => {
  try {
    return next(action)
  } catch (err) {
    logError(err, {
      extra: typeof action.type === 'string' ? action.type : 'unknown',
    })

    throw err
  }
}

const handleSagaError: SagaMiddlewareOptions['onError'] = (error, errorInfo) => {
  if (!serverSide) {
    datadogRum.addError(error, { saga: true })
  }

  logError(error, { extra: `redux-saga: ${errorInfo.sagaStack}` })
}

export function configureStore(initialState: any, reducer: Reducer, sagas: Sagas = []) {
  let enhancer: StoreEnhancer
  const middleware: Array<Middleware> = []
  const sagaMiddleware = createSagaMiddleware({ onError: handleSagaError })
  const development = process.env.NODE_ENV === 'development'

  middleware.push(sagaMiddleware)

  if (development) {
    const reduxDevTools =
      !serverSide &&
      // Airbnb config allows only window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      /* eslint-disable no-underscore-dangle */
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    /* eslint-enable */

    enhancer = compose(applyMiddleware(...middleware), reduxDevTools || compose)
  } else {
    enhancer = applyMiddleware(...middleware, crashReporter)
  }

  const store: Store = createStore(reducer, initialState, enhancer)

  const sagaRun = sagaMiddleware.run(function* rootSaga() {
    yield all([...getStaticSagas(), ...sagas])
  })

  return { store, sagaRun }
}

export function initStore(props: RailsReduxStoreProps, reducers?: Reducers, sagas?: Sagas) {
  const initialState = {
    ...props,
  }

  // When react is reloaded via react:reload event, ReactOnRails tries to initialize the store
  // again, which is not what we want. Instead, we grab the already existing one.

  if (globalStore) return globalStore

  return configureStore(initialState, createReducers(reducers), sagas).store
}
