import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { getSystemConfiguration } from 'state/system-configuration/selectors'
import { SystemConfigurationContext } from 'contexts/SystemConfigurationProvider'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

const useSystemConfiguration = () => {
  const isSysConfigurationRefactorEnabled = useFeatureSwitch('system_configuration_slice_refactor')
  const systemConfigurationFromRedux = useSelector(getSystemConfiguration)
  const systemConfigurationFromContext = useContext(SystemConfigurationContext)

  if (!isSysConfigurationRefactorEnabled) return systemConfigurationFromRedux

  if (!systemConfigurationFromContext) {
    throw new Error('useSystemConfiguration must be used within a SystemConfigurationProvider')
  }

  return systemConfigurationFromContext
}

export default useSystemConfiguration
