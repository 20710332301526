export enum PaymentDetailsType {
  Completed = 'completed',
  Pending = 'pending',
  Failed = 'failed',
}

export enum DebitStatus {
  New = 1,
  Processing = 10,
  Pending = 20,
  Failed = 30,
  Authorized = 35,
  Success = 40,
  PayoutAvailable = 50,
  PayoutProcessing = 60,
  PayoutFailed = 70,
  PayoutDone = 80,
  PayoutReverted = 85,
  PayoutPartiallyRefunded = 90,
  Refunded = 100,
}

export enum PaymentStatus {
  Success = 'success',
  Pending = 'pending',
  Failure = 'failure',
}
