import { PackageTypeDto } from 'types/dtos'
import { PackageTypeModel } from 'types/models'

import { transformDropOffTypes } from './drop-off'

export const transformPackageTypeDto = ({
  id,
  first_mile_carrier_id,
  last_mile_carrier_id,
  is_fixed_price,
  code,
  price,
  currency,
  title,
  subtitle,
  seller_price,
  weight_to,
  weight_from,
  weight_units,
  carrier_service,
  value_proposition,
  package_size_code,
  carrier_service_label,
  shipment_instructions,
  drop_off_types,
  buyer_phone_requirement,
  seller_phone_requirement,
}: PackageTypeDto): PackageTypeModel => ({
  id,
  firstMileCarrierId: first_mile_carrier_id,
  lastMileCarrierId: last_mile_carrier_id,
  isFixedPrice: is_fixed_price,
  code,
  price,
  currency,
  title,
  subtitle,
  sellerPrice: seller_price,
  weightFrom: weight_from,
  weightTo: weight_to,
  weightUnits: weight_units,
  carrierService: carrier_service,
  valueProposition: value_proposition,
  packageSizeCode: package_size_code,
  carrierServiceLabel: carrier_service_label,
  shipmentInstructions: shipment_instructions,
  dropOffTypes: transformDropOffTypes(drop_off_types),
  buyerPhoneRequirement: buyer_phone_requirement,
  sellerPhoneRequirement: seller_phone_requirement,
})
