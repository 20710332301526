import { AxiosError, AxiosResponse } from 'axios'

import { ResponseCode } from 'data/api/response-codes'
import { isObject, isValueInObject } from 'libs/utils/object'

export function isAxiosResponse<T>(response: unknown): response is AxiosResponse<T> {
  return (
    response != null &&
    isObject(response) &&
    'data' in response &&
    'status' in response &&
    'statusText' in response &&
    'headers' in response &&
    'config' in response &&
    'request' in response
  )
}

export function isAxiosError<T>(error: AxiosError | any): error is AxiosError<T> {
  return error?.isAxiosError
}

export function isAxiosInternalServerError(error: unknown): boolean {
  return (
    isAxiosError(error) &&
    ((!!error.response?.status && error.response.status >= 500) ||
      error.code === AxiosError.ECONNABORTED ||
      error.code === AxiosError.ETIMEDOUT)
  )
}

export const extractErrorResponseCode = (error: unknown): ResponseCode | undefined => {
  if (!isAxiosError(error)) return undefined

  const responseData = error?.response?.data

  if (!isObject(responseData)) return undefined
  if (!('code' in responseData)) return undefined

  const { code } = responseData

  if (typeof code !== 'number') return undefined
  if (!isValueInObject(code, ResponseCode)) return undefined

  return code
}
