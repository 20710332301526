import { DOMAIN_ALIASES } from 'constants/hostnames'

type Props = {
  url?: string
}

const HrefLangTags = ({ url }: Props) => {
  if (!url) return null

  const { host, pathname } = new URL(url)
  const environment = host.includes('sandbox') ? 'sandbox' : 'production'

  return (
    <>
      {Object.entries(DOMAIN_ALIASES[environment]).map(([locale, localeHost]) => (
        <link
          rel="alternate"
          href={`https://${localeHost}${pathname}`}
          hrefLang={locale}
          key={locale}
        />
      ))}
    </>
  )
}

export default HrefLangTags
