'use client'

import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { onLCP, LCPMetric, onCLS, onINP } from 'web-vitals'

import { systemTimingEvent } from 'libs/common/event-tracker/events'

import useTracking from 'hooks/useTracking'
import { MS_PER_SECOND } from 'constants/date'
import clientSideMetrics from 'libs/common/client-side-metrics'
import useBreakpoint from 'hooks/useBreakpoint'
import useSession from 'hooks/useSession'

import { breakpointsToDevice } from './utils'

const TrackWebVitals = () => {
  const { track } = useTracking()
  const { screen } = useSession()
  const isLcpTracked = useRef(false)
  const router = useRouter()
  const breakpoints = useBreakpoint()

  const device = breakpointsToDevice(breakpoints)

  useEffect(() => {
    onLCP((metric: LCPMetric) => {
      if (isLcpTracked.current) return

      clientSideMetrics
        .histogram(
          'largest_contentful_paint',
          {
            page: router.pathname,
            device,
          },
          [
            0.25, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
            15, 20,
          ],
        )
        .observe(metric.value / MS_PER_SECOND)

      const extra = {
        section: 'largest_contentful_paint',
        duration: metric.value,
        completionState: 'succeeded',
        data: screen,
      } as const

      track(systemTimingEvent(extra))
      isLcpTracked.current = true
    })
  }, [screen, track, router.pathname, device])

  useEffect(() => {
    onCLS(metric => {
      clientSideMetrics
        .histogram(
          'cumulative_layout_shift',
          {
            page: router.pathname,
            device,
          },
          [
            0.001, 0.01, 0.05, 0.1, 0.125, 0.15, 0.175, 0.2, 0.225, 0.25, 0.3, 0.35, 0.4, 0.5, 0.75,
            1, 5,
          ],
        )
        .observe(metric.value)
    })
  }, [router.pathname, device])

  useEffect(() => {
    onINP(metric => {
      clientSideMetrics
        .histogram(
          'interaction_to_next_paint',
          {
            page: router.pathname,
            device,
          },
          [0.005, 0.01, 0.025, 0.05, 0.1, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.6, 0.75, 1, 2, 5],
        )
        .observe(metric.value / MS_PER_SECOND)
    })
  }, [router.pathname, device])

  return null
}

export default TrackWebVitals
