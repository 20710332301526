import * as AppHealth from '@vinted/apphealth-web'

import { serverSide } from 'libs/utils/environment'

const AUTO_TEST_USER_EMAIL_ROOT = 'autotestvinted'

const appHealth = {
  initialize: (projectName: string, version: string, env: string) => {
    AppHealth.watch(projectName, {
      context: {
        version,
        env,
      },
    })
    window.apphealth = AppHealth
  },

  disableForTestUsers: (email: string | null | undefined) => {
    if (serverSide) return
    if (process.env.NODE_ENV !== 'production') return

    if (email?.includes(AUTO_TEST_USER_EMAIL_ROOT)) {
      AppHealth.unwatch()
    }
  },
}

export default appHealth
