import { put, takeEvery } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import { getFormattedAddress } from 'components/UserAddress/utils'
import { geocodeAddress } from 'data/utils/google'
import { transformNearbyDropOffPointDto } from 'data/transformers/nearby-drop-off-point'

import * as sessionSelectors from 'state/session/selectors'
import * as conversationSelectors from 'state/conversation/selectors'
import * as api from 'data/api'

import * as statelessActions from './actions'
import { actions } from './slice'
import { DropOffPointSource } from './constants'

export function* fetchNearbyDropOffPoints({
  payload,
}: ReturnType<typeof actions.fetchNearbyDropOffPointsRequest>) {
  const userCountryCode = yield* select(sessionSelectors.getUserCountryIsoCode)

  const { address } = payload

  const formattedAddress = yield call(getFormattedAddress, address)

  const countryCode = address?.countryIsoCode || userCountryCode

  let latitude: number | undefined
  let longitude: number | undefined
  let source: string | undefined

  const { coordinates, dropOffPointSource, limit } = payload

  if (coordinates) {
    latitude = coordinates.latitude
    longitude = coordinates.longitude
  } else if (formattedAddress) {
    const geocodedAddress = yield* call(geocodeAddress, formattedAddress)

    latitude = geocodedAddress?.geometry.location.lat()
    longitude = geocodedAddress?.geometry.location.lng()
    source = DropOffPointSource.UserAddress
  }

  yield put(
    statelessActions.fetchNearbyDropOffOptionsRequest({
      countryCode,
      longitude,
      latitude,
      dropOffPointSource: source || dropOffPointSource,
      limit,
    }),
  )
}

export function* fetchNearbyDropOffOptions({
  payload,
}: ReturnType<typeof statelessActions.fetchNearbyDropOffOptionsRequest>) {
  const shipmentId = yield* select(conversationSelectors.getShipmentId)
  const labelTypeId = yield* select(conversationSelectors.getDigitalLabelTypeId)

  if (!shipmentId) return

  const response = yield* call(api.getNearbyDropOffPoints, {
    shipmentId,
    labelTypeId,
    ...payload,
  })

  if (
    'errors' in response ||
    !response.nearby_drop_off_points?.drop_off_points.length ||
    !response.nearby_drop_off_points?.suggested_drop_off_point_code
  ) {
    yield put(actions.fetchNearbyDropOffPointFailure())

    return
  }

  const dropOffPointResponse = transformNearbyDropOffPointDto(response.nearby_drop_off_points)

  const { dropOffPoints, suggestedDropOffPointCode, carrier } = dropOffPointResponse

  const selectedPoint = dropOffPoints.find(point => point.code === suggestedDropOffPointCode)

  const { dropOffPointSource } = payload

  yield put(
    actions.setSelectedDropOffPoint({
      selectedDropOffPoint: selectedPoint,
    }),
  )

  yield put(
    actions.fetchNearbyDropOffPointSuccess({
      suggestedDropOffPointCode,
      dropOffPoints,
      dropOffPointCarrier: carrier,
      dropOffPointSource,
    }),
  )
}

export default function* saga() {
  yield takeEvery(statelessActions.fetchNearbyDropOffOptionsRequest, fetchNearbyDropOffOptions)
  yield takeEvery(actions.fetchNearbyDropOffPointsRequest, fetchNearbyDropOffPoints)
}
